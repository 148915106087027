import React from 'react';
import { Collapse, Alert } from 'react-bootstrap';
import { useErrorTranslation } from '#hooks';

export const ErrorAlert = ({ error }) => {
  const translateError = useErrorTranslation();

  const errorsList = error?.map((err) => translateError(err));

  return (
    <>
      {errorsList?.map((error, i) => (
        <Collapse in={true} appear={true} key={i}>
          <div className='d-grid'>
            <Alert variant='danger'>{error}</Alert>
          </div>
        </Collapse>
      ))}
    </>
  );
};
