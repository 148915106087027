import React from 'react';
import DocumentViewer from '#components/DocumentViewer';

const PrivacyPage = () => {
  return (
    <main>
      <DocumentViewer fileTranslationKey='privacy.file' titleTranslationKey='privacy.title' />
    </main>
  );
};

export default PrivacyPage;
