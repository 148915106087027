import React from 'react';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import { EyeIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Routes } from '#Routes';
import { useTranslation } from 'react-i18next';

export const MedicalDocumentWidget = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const Event = (props) => {
    const { link, startDate, title, lastItem } = props;
    const startDateYear = startDate.format('YYYY');
    const startDateMonth = startDate.format('MMM');
    const startDateDay = startDate.format('D');
    const rowClassName = lastItem ? '' : 'border-bottom pb-4 mb-4';

    return (
      <Row className={`align-items-center d-block d-sm-flex ${rowClassName}`}>
        <Col xs='auto' className='mb-3 mb-sm-0'>
          <div className='calendar d-flex'>
            <span className='calendar-month'>{startDateMonth}</span>
            <span className='calendar-day py-2'>{startDateDay}</span>
            <span className='calendar-year py-2'>{startDateYear}</span>
          </div>
        </Col>
        <Col>
          <Card.Link as={Link} to={link} className='mb-1'>
            <h5 className='mb-1'>{title}</h5>
          </Card.Link>
        </Col>
      </Row>
    );
  };

  return (
    <Card border='0' className='shadow'>
      <Card.Header className='border-bottom'>
        <h2 className='fs-5 fw-bold mb-1'>{t('dashboard.title-medical-documents')}</h2>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div className='d-flex justify-content-center my-5'>
            <Spinner></Spinner>
          </div>
        ) : (
          data?.map((doc, index) => (
            <Event
              key={doc.id}
              title={doc.title}
              startDate={moment(doc.startDate)}
              link={`/medical-documents/${doc.id}`}
              lastItem={index === data.length - 1}
            />
          ))
        )}
      </Card.Body>
      <Card.Footer
        as={Link}
        to={Routes.MedicalDocumentsList.path}
        className='border-top bg-gray-50'
      >
        <div className='d-flex align-items-center justify-content-center fw-bold'>
          <EyeIcon className='icon icon-xs me-2' />
          {t('dashboard.see-all')}
        </div>
      </Card.Footer>
    </Card>
  );
};
