import { createSlice } from '@reduxjs/toolkit';
import {
  authLogin,
  authRegister,
  authForgotPassword,
  authForgotPasswordSet,
  authLogout,
} from '#store/actions';
import { REHYDRATE } from 'redux-persist';

const initialState = {
  userToken: null, // for storing the JWT
  isLogoutPending: false,
  isEmailVerified: null,
  error: null,
};

const handlePending = (state) => {
  state.error = null;
};

const handleFulfilled = (state) => {
  state.error = null;
};

const handleRejected = (state, { payload }) => {
  if (typeof payload === 'object') {
    state.error = Object.keys(payload)
      .map((key) => payload[key])
      .flat();
  } else if (typeof payload === 'string') {
    state.error = [payload];
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [authLogin.pending]: handlePending,
    [authLogin.fulfilled]: (state, { payload }) => {
      handleFulfilled(state);
      state.userToken = payload.key;
      state.isEmailVerified = payload.user?.is_verified;
    },
    [authLogin.rejected]: handleRejected,
    [authRegister.pending]: handlePending,
    [authRegister.fulfilled]: (state, { payload }) => {
      handleFulfilled(state);
      state.userToken = payload.key;
      state.isEmailVerified = payload.user?.is_verified;
    },
    [authRegister.rejected]: handleRejected,
    [authForgotPassword.pending]: handlePending,
    [authForgotPassword.fulfilled]: handleFulfilled,
    [authForgotPassword.rejected]: handleRejected,
    [authForgotPasswordSet.pending]: handlePending,
    [authForgotPasswordSet.fulfilled]: handleFulfilled,
    [authForgotPasswordSet.rejected]: handleRejected,
    [authLogout.pending]: (state) => {
      state.isLogoutPending = true;
    },
    [authLogout.fulfilled]: (state) => {
      state.userToken = null;
      state.isLogoutPending = false;
    },
    [authLogout.rejected]: (state) => {
      state.userToken = null;
      state.isLogoutPending = false;
    },
    [REHYDRATE]: (state, { payload }) => {
      state.userToken = payload?.auth.isLogoutPending ? null : payload?.auth?.userToken;
      state.isLogoutPending = false;
      state.isEmailVerified = payload?.auth?.isEmailVerified;
      state.error = []; // use empty array to override persisted error
    },
  },
});

export const authReducer = authSlice.reducer;
export const authReducerPath = authSlice.name;
