import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ArrowNarrowLeftIcon, MailIcon } from '@heroicons/react/solid';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Collapse,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ErrorAlert } from '#atoms';
import { Routes } from '#Routes';
import { selectIsLogged, useResendConfirmationEmailMutation } from '#store';

const ResendEmailVerificationPage = () => {
  const { t } = useTranslation();
  const isLogged = useSelector(selectIsLogged);
  const patient = useSelector((state) => state.patient);
  const { register, handleSubmit } = useForm();
  const [resendConfirmEmail, { isLoading, isSuccess, isError, error: rawError }] =
    useResendConfirmationEmailMutation();

  const error = useMemo(() => {
    if (!rawError) return undefined;
    return rawError?.status === 400 ? rawError.data.email || rawError.data : ['Unexpected error'];
  }, [rawError]);

  const onFormSubmit = async (data) => {
    await resendConfirmEmail(data);
  };

  return (
    <main>
      <section className='d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft'>
        <Container>
          <Row className='justify-content-center'>
            <p className='text-center'>
              <Card.Link
                as={Link}
                to={isLogged ? Routes.Account.path : Routes.Login.path}
                className='d-flex align-items-center justify-content-center'
              >
                <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
                {isLogged ? t('auth.backToAccount') : t('auth.backToLogin')}
              </Card.Link>
            </p>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <h3>{t('auth.resendEmailVerification')}</h3>
                <p className='mb-4'>{t('auth.resendEmailVerificationDescription')}</p>

                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className='mb-4'>
                    <Form.Label htmlFor='email'>{t('auth.email')}</Form.Label>
                    <InputGroup id='email'>
                      <Form.Control
                        autoFocus
                        type='email'
                        placeholder='john@company.com'
                        autoComplete='email'
                        {...register('email', { required: true, value: patient.email })}
                      />
                    </InputGroup>
                  </div>

                  {isSuccess && (
                    <Collapse in={true} appear={true}>
                      <div className='d-grid'>
                        <Alert variant='success'>
                          {t(`auth.resendEmailVerificationSuccessPrompt`)}
                        </Alert>
                      </div>
                    </Collapse>
                  )}

                  {!isSuccess && (
                    <>
                      {isError && <ErrorAlert error={error} />}

                      <div className='d-grid'>
                        <Button variant='primary' size='lg' type='submit' disabled={isLoading}>
                          {isLoading ? (
                            <Spinner size='sm' />
                          ) : (
                            <>
                              <MailIcon className='icon icon-sm' />{' '}
                              {t('auth.resendEmailVerificationSubmit')}
                            </>
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResendEmailVerificationPage;
