import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { Routes } from '#Routes';
import moment from 'moment';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Document } from '#atoms';

export const HealthReportsGallery = ({ data = [] }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        {data?.map?.((document) => (
          <Col lg={6} key={document.id} className='mb-3'>
            <Document isMiniature>
              <Document.Title>
                {t('health-reports-gallery.title')}
                <div className='h6'>
                  {t('health-reports-gallery.dated')}
                  <span> {moment(document.created).format('y-MM-DD HH:mm')}</span>
                </div>
              </Document.Title>
              <Document.Subtitle>{document.report_title}</Document.Subtitle>
              <Document.Loader
                isLoading={document.process_status === 'IN_PROGRESS'}
                text={t('health-reports-gallery.processing')}
              />
              <Document.Truncated>{document.report_summary}</Document.Truncated>
              <Document.Actions className='mt-auto'>
                <Button as={Link} to={generatePath(Routes.HealthReport.path, { id: document.id })}>
                  {t('health-reports-gallery.view-report')}
                  <ArrowNarrowRightIcon className='icon icon-xs ms-2' />
                </Button>
              </Document.Actions>
            </Document>
          </Col>
        ))}
      </Row>
    </>
  );
};
