import { useTranslation } from 'react-i18next';

const translationPrefix = ['errors', 'errors.form', 'errors.api'];

export const useErrorTranslation = () => {
  const {
    t,
    i18n: { exists },
  } = useTranslation();

  return (error) => {
    const translationKeys = translationPrefix.map((prefix) => `${prefix}.${error}`);
    const definedKey = translationKeys.find((key) => exists(key));

    return t(definedKey) || error;
  };
};
