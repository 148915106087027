import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authLogout } from '#store';

function useLogout() {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await dispatch(authLogout());
  }, [dispatch]);
}

export default useLogout;
