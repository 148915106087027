import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionCheckout, ProductsPricing } from '#components';

export default function SubscriptionPage() {
  const { t } = useTranslation();

  const [selectedProduct, setSelectedProduct] = useState(null);

  const unselectProduct = useCallback(() => setSelectedProduct(null), [setSelectedProduct]);

  return (
    <>
      <div className='d-block py-4'>
        <h3 className='text-center'> {t('subscription.title')}</h3>
        <p className='mb-0 text-center'>{t('subscription.subtitle')}</p>
      </div>

      <ProductsPricing showActiveProducts onSelectProduct={setSelectedProduct} />

      <SubscriptionCheckout product={selectedProduct} onUnselectProduct={unselectProduct} />
    </>
  );
}
