import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FolderDownloadIcon, TrashIcon, CollectionIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from '#Routes';
import { alert } from '#utils';
import { useDeleteHealthReportMutation } from '#store';

export const HealthReportActions = ({ toPDF, data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [deleteHealthReportMutation] = useDeleteHealthReportMutation();

  const processingInProgressAlert = () => {
    alert.fire({
      icon: 'warning',
      title: t('health-report-actions.processing-title'),
      text: t('health-report-actions.processing-text'),
      confirmButtonText: 'Ok',
    });
  };

  const viewHealthReportsList = () => {
    navigate(Routes.HealthReportsList.path);
  };

  const deleteHealthReport = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        icon: 'warning',
        title: t('health-report-actions.delete-title'),
        text: t('health-report-actions.delete-text'),
        showCancelButton: true,
        confirmButtonText: t('health-report-actions.delete-confirm-button'),
        cancelButtonText: t('health-report-actions.delete-cancel-button'),
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert.fire({
            title: t('health-report-actions.deleting-title'),
            didOpen: () => alert.showLoading(),
          });

          const { error } = await deleteHealthReportMutation({ id: data.id });

          if (error) {
            return alert.fire({
              icon: 'error',
              title: t('health-report-actions.deleting-error-title'),
              text:
                error.data?.message ||
                t('health-report-actions.deleting-error-text'),
            });
          }

          return alert
            .fire({
              icon: 'success',
              title: t('health-report-actions.deleted-title'),
              text: t('health-report-actions.deleted-text'),
            })
            .then(viewHealthReportsList);
        }
      });
  };

  const downloadHealthReport = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        title: t('health-report-actions.download-title'),
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        didOpen: async () => {
          alert.showLoading();
          await toPDF();
          alert.close();
        },
      })
      .then(() => {
        return alert.fire({
          icon: 'success',
          title: t('health-report-actions.download-title2'),
          text: t('health-report-actions.download-text'),
        });
      });
  };

  if (!data) return;

  return (
    <div>
      <OverlayTrigger overlay={<Tooltip className='m-0'>{t('health-report-actions.list')}</Tooltip>}>
        <Button variant='gray-800' className='ms-2' onClick={viewHealthReportsList}>
          <CollectionIcon className={'icon icon-xs text-white'} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip className='m-0'>{t('health-report-actions.download')}</Tooltip>}>
        <Button variant='gray-800' className='ms-2' onClick={downloadHealthReport}>
          <FolderDownloadIcon className={'icon icon-xs text-white'} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip className='m-0'>{t('health-report-actions.delete')}</Tooltip>}>
        <Button variant='danger' className='ms-2' onClick={deleteHealthReport}>
          <TrashIcon className={'icon icon-xs text-white'} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
