export const MEDICAL_DOCUMENT_ACTION_TYPES = {
  CONFIRM: 'CONFIRM',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  EDIT: 'EDIT',
  LIST: 'LIST',
  PROCESS: 'PROCESS',
  REPORT: 'REPORT',
  VIEW: 'VIEW',
};
