import React, { useMemo } from 'react';
import CountUp from 'react-countup';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowRightIcon, BellIcon, CheckIcon } from '@heroicons/react/solid';
import cx from 'classnames';

const ProductFeatures = ({ product }) => {
  const { name, periodType } = product;

  const {
    t,
    i18n: { exists },
  } = useTranslation();

  const productPlanKey = `products.plan.${name}`;

  const features = useMemo(() => {
    const featureKey = `${productPlanKey}.features`;
    const featurePeriodKey = `${productPlanKey}.features-by-period.${periodType}`;

    return [
      ...(exists(featureKey) ? t(featureKey, { returnObjects: true }) : []),
      ...(exists(featurePeriodKey) ? t(featurePeriodKey, { returnObjects: true }) : []),
    ];
  }, [periodType, productPlanKey, t, exists]);

  return features.map((text, index) => (
    <div
      key={`feature-${index}`}
      className={cx('d-flex align-items-center', { 'mb-3': index < features.length - 1 })}
    >
      <CheckIcon className='icon icon-sm me-2' />
      <span>{text}</span>
    </div>
  ));
};

const ProductPricingItem = ({ isSubscriptionActive, subscription, product, selectProduct }) => {
  const { price, currency, periodType, name } = product;

  const { t } = useTranslation();

  const handleSelectProduct = (e) => {
    if (selectProduct) {
      e.preventDefault();
      selectProduct(product);
    }
  };

  const isYearPeriod = periodType === 'year';

  const priceMonthly = useMemo(() => {
    const priceMonthly = isYearPeriod ? price / 12 : price;
    return Math.ceil(priceMonthly / 100);
  }, [price, isYearPeriod]);

  const productPlanKey = `products.plan.${name}`;

  const isPremiumProduct = t(`${productPlanKey}.tag`).toLowerCase() === 'pro';

  const variant = isPremiumProduct ? 'secondary' : 'gray-800';

  return (
    <Card className={cx('mb-4 mb-xl-0 h-100', { 'bg-gray-50': isSubscriptionActive })}>
      <Card.Header className='border-gray-100 py-5 px-4'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex mb-3'>
            <h5 className='mb-0'>{currency}</h5>
            <span className={`display-2 text-${variant} mb-0`}>
              <CountUp start={0} end={priceMonthly} duration={0.5} />
            </span>
            <h6 className='fw-normal align-self-end'>/{t(`periods.month`).toLowerCase()}</h6>
          </div>
          {isYearPeriod && (
            <div className='d-flex mb-3 text-gray-600 fw-bold'>
              <span className='mb-0'>{currency}</span>
              <span>
                <span className='display-4'>{price / 100}</span>
                <span className='align-self-end'>/{t(`periods.year`).toLowerCase()}</span>
              </span>
            </div>
          )}
        </div>
        <h4 className='mb-3 text-black'>{t(`${productPlanKey}.title`)}</h4>
        <p className='mb-0'>{t(`${productPlanKey}.description`)}</p>
      </Card.Header>
      <Card.Body className='py-5 px-4'>
        <ProductFeatures product={product} />
      </Card.Body>
      <Card.Footer className='border-gray-100 d-grid px-4 pb-4'>
        {isSubscriptionActive ? (
          <Button variant='outline-success' className='fw-bold' disabled>
            {t(`products.subscription-active`)}
            {subscription &&
              subscription?.periodType !== periodType &&
              ' - ' + t(`periodically.${subscription.periodType}`)}
          </Button>
        ) : isYearPeriod ? (
          <Button variant='outline-primary' disabled>
            {t(`products.button-available-soon`)} <BellIcon className='icon icon-xs' />
          </Button>
        ) : (
          <Button variant={variant} onClick={handleSelectProduct}>
            {t(`${productPlanKey}.button`)} <ArrowNarrowRightIcon className='icon icon-xs ms-3' />
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default React.memo(ProductPricingItem);
