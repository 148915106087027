import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon } from '@heroicons/react/solid';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Collapse,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ErrorAlert } from '#atoms';
import { Routes } from '#Routes';
import BgImage from '#assets/img/illustrations/signin.svg';
import { useAuth } from '#hooks';

const LoginPage = () => {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm();

  const { login, redirectWhenLoggedIn } = useAuth();

  useEffect(redirectWhenLoggedIn);

  return (
    <main>
      <section className='d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft'>
        <Container>
          <p className='text-center'>
            <Card.Link
              as={Link}
              to={Routes.Home.path}
              className='d-flex align-items-center justify-content-center'
            >
              <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
              {t('auth.backToHomepage')}
            </Card.Link>
          </p>
          <Row
            className='justify-content-center form-bg-image'
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center text-md-center mb-4 mt-md-0'>
                  <h3 className='mb-0'>{t('auth.loginPlatform')}</h3>
                </div>
                <Form className='mt-4' onSubmit={handleSubmit(login)}>
                  <Form.Group id='email' className='mb-4'>
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type='email'
                        placeholder={t('auth.email-placeholder')}
                        autoComplete='username'
                        {...register('email', { required: true })}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id='password' className='mb-4'>
                      <Form.Label>{t('auth.password')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className='icon icon-xs text-gray-600' />
                        </InputGroup.Text>
                        <Form.Control
                          type='password'
                          placeholder={t('auth.password')}
                          autoComplete='current-password'
                          {...register('password', { required: true })}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className='d-flex justify-content-between align-items-top mb-4'>
                      <Form.Check type='checkbox'>
                        <FormCheck.Input id='defaultCheck5' className='me-2' />
                        <FormCheck.Label htmlFor='defaultCheck5' className='mb-0'>
                          {t('auth.remember')}
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link
                        as={Link}
                        to={Routes.ForgotPassword.path}
                        className='small text-end'
                      >
                        {t('auth.lostPassword')}
                      </Card.Link>
                    </div>
                  </Form.Group>

                  {isSubmitSuccessful &&
                    (error?.includes('E-mail is not verified.') ? (
                      <Collapse in={true} appear={true}>
                        <div className='d-grid'>
                          <Alert variant='warning'>
                            {t('auth.emailNotVerified')}{' '}
                            <Card.Link
                              as={Link}
                              to={Routes.ResendEmailVerification.path}
                              className='fw-bold'
                            >
                              {t('auth.resendEmailVerification')}
                            </Card.Link>
                          </Alert>
                        </div>
                      </Collapse>
                    ) : (
                      <ErrorAlert error={error} />
                    ))}

                  <div className='d-grid'>
                    <Button variant='gray-800' size='lg' type='submit' disabled={isSubmitting}>
                      {isSubmitting ? <Spinner size='sm' /> : t('auth.login')}
                    </Button>
                  </div>
                </Form>

                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <span className='fw-normal'>
                    {t('auth.notRegistered')}{' '}
                    <Card.Link as={Link} to={Routes.Register.path} className='fw-bold'>
                      {t('auth.createAccount')}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default LoginPage;
