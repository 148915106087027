import i18n from 'i18next';
import en from './en/translation.json';
import pl from './pl/translation.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import moment from 'moment';
import 'moment/locale/pl';

export const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
  })
  .then(() => {
    handleLocaleChange(i18n.language);
  });

const handleLocaleChange = (language) => {
  moment.locale(language);
};

i18n.on('languageChanged', handleLocaleChange);

export default i18n;
