import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniqueNamesGenerator, adjectives, colors, names } from 'unique-names-generator';
import { API_URL, ERRORS } from '#const';
import { baseApi } from '#store/apis/baseApi';
import { toast } from 'react-toastify';
import { Routes } from '#Routes';
import { Router } from '#Router';
import i18n from '#i18n/config';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const parseErrors = ({ error, rejectWithValue }) => {
  if (error?.response?.status === 500) {
    return rejectWithValue({ error: ERRORS.SERVER_ERROR });
  } else if (error.response && error.response.data) {
    return rejectWithValue(error.response.data);
  } else {
    return rejectWithValue(error.message);
  }
};

export const authRegister = createAsyncThunk(
  'auth/register',
  async ({ email, password }, { rejectWithValue }) => {
    const customConfig = {
      dictionaries: [adjectives, colors, names],
      separator: '-',
      length: 3,
    };

    const randomName = uniqueNamesGenerator(customConfig);
    try {
      const username = randomName;
      const { data } = await axios.post(
        `${API_URL}/accounts/registration/`,
        // TODO: adjust request props after backend update
        { email, password1: password, password2: password, name: username, role: 'patient' },
        config,
      );
      return data;
    } catch (error) {
      return parseErrors({ error, rejectWithValue });
    }
  },
);

export const authLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${API_URL}/accounts/login/`, { email, password }, config);
      return data;
    } catch (error) {
      return parseErrors({ error, rejectWithValue });
    }
  },
);

export const authLogout = createAsyncThunk(
  'auth/logout',
  async (_, { getState, dispatch }) => {
    try {
      const { userToken } = getState().auth;

      // reset baseApi state
      dispatch(baseApi.util.resetApiState());

      Router.navigate(Routes.Home.path);

      if (!userToken) return; // user is already logout

      toast.info(i18n.t('auth.logoutToast'));

      const { data } = await axios.post(
        `${API_URL}/accounts/logout/`,
        {},
        {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      return data;
    } catch (error) {
      // do nothing, always logout even if server reject
    }
  },
  {
    condition: (_, { getState }) => {
      // prevent to dispatch multiple logout action
      return !getState().auth?.isLogoutPending;
    },
  },
);

export const authForgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${API_URL}/accounts/password/reset/`, { email }, config);
      return data;
    } catch (error) {
      return parseErrors({ error, rejectWithValue });
    }
  },
);

export const authForgotPasswordSet = createAsyncThunk(
  'auth/forgotPasswordSet',
  async ({ password, token, uid }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${API_URL}/accounts/password/reset/confirm/`,
        { new_password1: password, new_password2: password, token, uid },
        config,
      );
      return data;
    } catch (error) {
      return parseErrors({ error, rejectWithValue });
    }
  },
);
