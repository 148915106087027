import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LaboratoryTestsList from '../../components/LaboratoryTestsList';
import { useFetchLaboratoryTestGroupsQuery } from '#store/apis/laboratoryTestsApi';

const LIMIT_PER_PAGE = 50;

const LaboratoryTestsListPage = ({ limit = LIMIT_PER_PAGE, language }) => {
  const { t, i18n } = useTranslation();

  const query = {
    limit,
    language: language || i18n.language,
  };

  const { data, isLoading, error } = useFetchLaboratoryTestGroupsQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  if (isLoading) return <div>{t('laboratory-tests.loading')}</div>;
  if (error) return <div>{t('laboratory-tests.error')}</div>;

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block'>
          <h4>{t('laboratory-tests.title')}</h4>
          <p className='mb-0'>{t('laboratory-tests.subtitle')}</p>
        </div>
      </div>

      <Card border='0' className='table-wrapper shadow overflow-hidden'>
        <Card.Body>
          {data.results && data.results.length > 0 ? (
            <LaboratoryTestsList laboratoryTests={data.results} totalItems={data.count} />
          ) : (
            <Card className='shadow'>
              <Card.Body>
                <Card.Title>{t('laboratory-tests.no-data')}</Card.Title>
                <Card.Text>
                  <p>{t('laboratory-tests.no-data-instructions')}</p>
                  <p>{t('laboratory-tests.no-data-instructions-1')}</p>
                  <p>{t('laboratory-tests.no-data-instructions-2')}</p>
                  <p>{t('laboratory-tests.no-data-instructions-3')}</p>
                  <p>
                    <b>{t('laboratory-tests.no-data-instructions-4')}</b>
                  </p>
                </Card.Text>
                <Button as={Link} to='/medical-documents' variant='primary' className='mt-3'>
                  {t('laboratory-tests.go-to-medical-documents')}
                </Button>
              </Card.Body>
            </Card>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default LaboratoryTestsListPage;
