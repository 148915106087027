import React, { useState, useEffect } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import UnitedKingdomFlag from '#assets/img/flags/gb.svg';
import PlFlag from '#assets/img/flags/pl.svg';

const TranslationItem = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const options = [
    {
      value: 'en',
      label: (
        <span>
          <img src={UnitedKingdomFlag} width='20' alt='English' />
        </span>
      ),
    },
    {
      value: 'pl',
      label: (
        <span>
          <img src={PlFlag} width='20' alt='Polish' />
        </span>
      ),
    },
  ];

  const [currentLang, setCurrentLang] = useState(lang || 'pl');
  const [langLabel, setLangLabel] = useState(
    options.find((option) => option.value === currentLang).label,
  );

  useEffect(() => {
    setCurrentLang(lang || 'en');
    setLangLabel(options.find((option) => option.value === (lang || 'pl')).label);
  }, [lang]);

  const changeLanguage = (selectedLang) => {
    i18n.changeLanguage(selectedLang);
    setCurrentLang(selectedLang);
    setLangLabel(options.find((option) => option.value === selectedLang).label);
    navigate(`/${selectedLang}${window.location.pathname.substring(3)}${window.location.hash}`);
  };

  return (
    <Dropdown as={ButtonGroup} className=''>
      <Dropdown.Toggle className='text-secondary bg-transparent' id='lng-dropdown'>
        {langLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.value} onClick={() => changeLanguage(option.value)}>
            {option.label}
            <span className='ms-2 fw-bold'>{t(`languages.${option.value}`)}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TranslationItem;
