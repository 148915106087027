import React from 'react';
import ContactForm from '../components/ContactForm';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <main className='content'>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
          <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
            <li className="breadcrumb-item">
              <a href="/"><span className="fas fa-home"></span></a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Contact</li>
          </ol>
        </nav>
      </div> */}

      <div className='row justify-content-md-center'>
        <div className='col-md-8 mb-4 mb-md-0'>
          <h2 className='font-weight-bold mb-5 mt-5'>{t('contact-page.contact-us')}</h2>
          <ContactForm />
        </div>
      </div>
    </main>
  );
};

export default ContactPage;
