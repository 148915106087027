import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

export function SelectBox({ name, type, form, options }) {
  const { register } = form;

  return (
    <Form.Group key={`inline-${type}`} className='select-box'>
      <Row>
        {options.map((option) => (
          <Col key={`${option.name}`}>
            <Form.Check
              inline
              label={option.name}
              className='select-box__check form-floating'
              name={name}
              value={option.value}
              type={type}
              id={`inline-${option.name}`}
              {...register(name, { required: true })}
            />
          </Col>
        ))}
      </Row>
    </Form.Group>
  );
}
