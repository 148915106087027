import React, { useState } from 'react';
import { Card, ListGroup, Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchCounterQuery } from '#store';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import CreditsHistoryItem from './CreditsHistoryItem';

const LIMIT_PER_PAGE = 5;

const CreditsHistory = ({ limit = LIMIT_PER_PAGE }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * limit;

  const { data } = useFetchCounterQuery({ offset, limit }, { refetchOnMountOrArgChange: true });

  const totalItems = data?.count ?? 0;

  const pageNumbers = Math.ceil(totalItems / limit);

  const pages = Array.from(Array(pageNumbers).keys());

  const onPrevPage = () => setCurrentPage((p) => Math.max(p - 1, 0));
  const onNextPage = () => setCurrentPage((p) => p + 1);
  const onPageChange = (p) => setCurrentPage(p);

  if (totalItems === 0) return;

  return (
    <Card border='0' className='shadow p-0 p-md-4 h-100'>
      <Card.Header className='border-bottom p-3'>
        <h5>{t('account.credits.list')}</h5>
      </Card.Header>
      <Card.Body className='px-0'>
        <ListGroup className='list-group-flush list-group-timeline'>
          {data?.results?.map((creditCounter) => (
            <CreditsHistoryItem key={creditCounter.id} creditCounter={creditCounter} />
          ))}
        </ListGroup>
        {pageNumbers > 1 && (
          <Pagination size={totalItems} className='mt-3'>
            <Pagination.Prev disabled={currentPage === 0} onClick={onPrevPage}>
              <ChevronDoubleLeftIcon className='icon icon-xs' />
            </Pagination.Prev>
            {pages.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => onPageChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}

            <Pagination.Next disabled={currentPage === pages.length - 1} onClick={onNextPage}>
              <ChevronDoubleRightIcon className='icon icon-xs' />
            </Pagination.Next>
          </Pagination>
        )}
      </Card.Body>
    </Card>
  );
};

export default React.memo(CreditsHistory);
