import React from 'react';
import DocumentViewer from '#components/DocumentViewer';

const TermsPage = () => {
  return (
    <main>
      <DocumentViewer fileTranslationKey='terms.file' titleTranslationKey='terms.title' />
    </main>
  );
};

export default TermsPage;
