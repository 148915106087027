import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, message } = formData;
    const mailtoLink = `mailto:info@medify.me?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\nAccount Email: ${email}\n\nMessage:\n${message}`)}`;
    window.location.href = mailtoLink;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-outline mb-4">
        <label className="form-label" htmlFor="formName">{t('contact-form.name')}</label>
        <input
          type="text"
          id="formName"
          name="name"
          className="form-control"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-outline mb-4">
        <label className="form-label" htmlFor="formEmail">{t('contact-form.account-email')}</label>
        <input
          type="email"
          id="formEmail"
          name="email"
          className="form-control"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-outline mb-4">
        <label className="form-label" htmlFor="formSubject">{t('contact-form.subject')}</label>
        <input
          type="text"
          id="formSubject"
          name="subject"
          className="form-control"
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-outline mb-4">
        <label className="form-label" htmlFor="formMessage">{t('contact-form.message')}</label>
        <textarea
          id="formMessage"
          name="message"
          className="form-control"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <button type="submit" className="btn btn-gray-800 btn-block">{t('contact-form.send')}</button>
    </form>
  );
};

export default ContactForm;
