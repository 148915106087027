import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowLeftIcon, MailIcon } from '@heroicons/react/solid';
import { Col, Row, Card, Container, Collapse, Spinner, Alert } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ErrorAlert } from '#atoms';
import { Routes } from '#Routes';
import { selectIsLogged, useConfirmEmailMutation } from '#store';

const EmailVerificationPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const isLogged = useSelector(selectIsLogged);
  const [confirmEmail, { isLoading, isSuccess, isError }] = useConfirmEmailMutation();

  useEffect(() => {
    confirmEmail(token);
  }, [confirmEmail, token]);

  useEffect(() => {
    if (isSuccess) {
      const timeoutId = setTimeout(() => {
        navigate(Routes.Dashboard.path);
      }, 5000);

      return () => {
        clearInterval(timeoutId);
      };
    }
  }, [navigate, isSuccess]);

  return (
    <main>
      <section className='d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft'>
        <Container>
          <Row className='justify-content-center'>
            <p className='text-center'>
              <Card.Link
                as={Link}
                to={isLogged ? Routes.Dashboard.path : Routes.Login.path}
                className='d-flex align-items-center justify-content-center'
              >
                <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
                {isLogged ? t('auth.backToDashboard') : t('auth.backToLogin')}
              </Card.Link>
            </p>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <h3>
                  <MailIcon className='icon icon-sm' /> {t('auth.emailVerification')}
                </h3>
                <p className='mb-4'>{t('auth.emailVerificationDescription')}</p>

                {isLoading && (
                  <div className='text-center'>
                    <Spinner />
                  </div>
                )}

                {isSuccess && (
                  <Collapse in={true} appear={true}>
                    <div className='d-grid'>
                      <Alert variant='success'>{t(`auth.emailVerificationSuccessPrompt`)}</Alert>
                    </div>
                  </Collapse>
                )}

                {isError && <ErrorAlert error={[t(`auth.emailVerificationFailedPrompt`)]} />}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default EmailVerificationPage;
