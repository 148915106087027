import React from 'react';
import DocumentViewer from '#components/DocumentViewer';

const DisclaimersPage = () => {
  return (
    <main>
      <DocumentViewer
        fileTranslationKey='disclaimers.file'
        titleTranslationKey='disclaimers.title'
      />
    </main>
  );
};

export default DisclaimersPage;
