import React, { useCallback } from 'react';
import moment from 'moment';
import { Col, Row, Card, Image, Container, Accordion } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationBar, ProductsPricing } from '#components';
import { Routes } from '#Routes';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function HomePage() {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentYear = moment().get('year');

  React.useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const selectProduct = useCallback(() => {
    navigate(Routes.AccountSubscribe.path);
  }, [navigate]);

  const handleClick = (video) => {
    ReactGA.event({
      category: 'Video Clicks',
      action: 'Video Click',
      label: video,
    });
  };

  const handleClickDoc = (doc) => {
    ReactGA.event({
      category: 'Doc Clicks',
      action: 'Doc Click',
      label: doc,
    });
  };

  return (
    <>
      <NavigationBar />

      <section
        className='section-header pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white overflow-hidden'
        id='home'
      >
        <Container>
          <Row>
            <Col xs={12} className='text-center'>
              <h1 className='fw-bolder text-secondary mb-5 mt-5'>{t('home.content.title1')}</h1>
              <p className='fw-light mb-5 h3'>{t('home.content.subtitle1')} </p>
              <p className='fw-light mb-5 h3'>{t('home.content.subtitle2')} </p>
            </Col>
          </Row>
          <div className='react-homepage-icon d-none d-lg-block'>
            <Image
              src='/medical_document2.png'
              width='100%'
              style={{ filter: 'brightness(0.4) blur(1px)', maxWidth: '100%', height: 'auto' }}
              alt={t('image.altText')}
            />
          </div>
        </Container>
      </section>

      <section className='section section-md bg-soft pt-lg-3' id='features'>
        <Container>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={12} className='order-lg-1'>
              <h2 className='mt-3 text-center'>{t('home.row0.title')}</h2>
              <p className='mb-3'>{t('home.row0.text1')}</p>
              <p className='mb-3'>{t('home.row0.text2')}</p>
              <p className='mb-3' style={{ position: 'relative', paddingBottom: '2rem' }}>
                {t('home.row0.text3')}
                <span
                  style={{
                    display: 'block',
                    borderBottom: '2px solid #ccc',
                    width: '50%',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: '25%',
                  }}
                ></span>
              </p>
              <p className='mb-3 pt-4'>{t('home.row0.text4')}</p>
              <p className='mb-3'>{t('home.row0.text5')}</p>
              <p className='mb-3' style={{ position: 'relative', paddingBottom: '2rem' }}>
                {t('home.row0.text6')}
                <span
                  style={{
                    display: 'block',
                    borderBottom: '2px solid #ccc',
                    width: '50%',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: '25%',
                  }}
                ></span>
              </p>
              <p className='mb-3 pt-4'>{t('home.row0.text7')}</p>
              <p className='mb-3'>{t('home.row0.text8')}</p>
              <p className='mb-3' style={{ position: 'relative', paddingBottom: '2rem' }}>
                {t('home.row0.text9')}
                <span
                  style={{
                    display: 'block',
                    borderBottom: '2px solid #ccc',
                    width: '50%',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: '25%',
                  }}
                ></span>
              </p>
              <Image
                src={i18n.language === 'en' ? '/create_document_en.svg' : '/create_document_pl.svg'}
                alt={t('home.image.alt-text-create-document')}
                className='img-fluid'
              />
              <p className='mb-3' style={{ position: 'relative', paddingBottom: '2rem' }}>
                <span
                  style={{
                    display: 'block',
                    borderBottom: '2px solid #ccc',
                    width: '50%',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: '25%',
                  }}
                ></span>
              </p>
              <Image
                src={
                  i18n.language === 'en'
                    ? '/report_ai_document_en.svg'
                    : '/report_ai_document_pl.svg'
                }
                alt={t('home.image.alt-text-create-ai-report-document')}
                className='img-fluid'
              />
              <p className='mb-3' style={{ position: 'relative', paddingBottom: '2rem' }}>
                <span
                  style={{
                    display: 'block',
                    borderBottom: '2px solid #ccc',
                    width: '50%',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: '25%',
                  }}
                ></span>
              </p>
              <Image
                src={
                  i18n.language === 'en' ? '/health_report_ai_en.svg' : '/health_report_ai_pl.svg'
                }
                alt={t('home.image.alt-text-create-health-report')}
                className='img-fluid'
              />
              <p className='mb-3' style={{ position: 'relative', paddingBottom: '2rem' }}>
                <span
                  style={{
                    display: 'block',
                    borderBottom: '2px solid #ccc',
                    width: '50%',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: '25%',
                  }}
                ></span>
              </p>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={6} className='order-lg-2 mb-5 mb-lg-0'>
              <h2 className='mt-3'>{t('home.row1.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row1.subtitle')}</p>
              <p className='mb-3'>{t('home.row1.text1')}</p>
              <p className='mb-3'>{t('home.row1.text2')}</p>
              <p className='mb-3'>{t('home.row1.text3')}</p>
              <p className='mb-3'>{t('home.row1.text4')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <Image
                src='/data_safety_2_1.png'
                alt={t('image.altText')}
                style={{ height: 'auto', maxHeight: '400px' }}
                className='img-fluid'
              />
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={6}>
              <h2 className='mb-3'>{t('home.row2.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row2.subtitle')}</p>
              <p className='mb-3'>{t('home.row2.text1')}</p>
              <p className='mb-3'>{t('home.row2.text2')}</p>
              <p className='mb-3'>{t('home.row2.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <video
                width='100%'
                controls
                title={t('home.row2.video-title')}
                aria-label={t('home.row2.video-description')}
                alt={t('home.row2.video-alt')}
                onClick={() => handleClick('Video1')}
              >
                <source src='/lab_test_720_4x.mp4' type='video/mp4' />
                {t('video.notSupported')}
              </video>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={6} className='order-lg-2 mb-5 mb-lg-0'>
              <h2>{t('home.row3.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3.subtitle')}</p>
              <p className='mb-3'>{t('home.row3.text1')}</p>
              <p className='mb-3'>{t('home.row3.text2')}</p>
              <p className='mb-3'>{t('home.row3.text3')}</p>
            </Col>
            <Col lg={6}>
              <video
                width='100%'
                controls
                title={t('home.row3.video-title')}
                aria-label={t('home.row3.video-description')}
                alt={t('home.row3.video-alt')}
                onClick={() => handleClick('Video2')}
              >
                <source src='/create_doc_report_720_4x.mp4' type='video/mp4' />
                {t('video.notSupported')}
              </video>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={6}>
              <h2>{t('home.row3_1.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3_1.subtitle')}</p>
              <p className='mb-3'>{t('home.row3_1.text1')}</p>
              <p className='mb-3'>{t('home.row3_1.text2')}</p>
              <p className='mb-3'>{t('home.row3_1.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <video
                width='100%'
                controls
                title={t('home.row3_1.video-title')}
                aria-label={t('home.row3_1.video-description')}
                alt={t('home.row3_1.video-alt')}
                onClick={() => handleClick('Video3')}
              >
                <source src='/create_health_report_720_4x.mp4' type='video/mp4' />
                {t('video.notSupported')}
              </video>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={6} className='order-lg-2 mb-5 mb-lg-0'>
              <h2 className='mt-3'>{t('home.row3_3.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3_3.subtitle')}</p>
              <p className='mb-3'>{t('home.row3_3.text1')}</p>
              <p className='mb-3'>{t('home.row3_3.text2')}</p>
              <p className='mb-3'>{t('home.row3_3.text3')}</p>
              <p className='mb-3'>{t('home.row3_3.text4')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <Image
                src='/chart_biomarker.png'
                alt={t('home.image.alt-text-chart-biomarkers')}
                style={{ height: 'auto', maxHeight: '500px' }}
                className='img-fluid'
              />
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={6}>
              <h2>{t('home.row3_4.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3_4.subtitle')}</p>
              <p className='mb-3'>{t('home.row3_4.text1')}</p>
              <p className='mb-3'>{t('home.row3_4.text2')}</p>
              <p className='mb-3'>{t('home.row3_4.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <Image
                src='/pdf_lista_ostatnich_badan.png'
                alt={t('home.image.alt-text-pdf-report')}
                style={{ height: 'auto', maxHeight: '500px' }}
                className='img-fluid'
              />
            </Col>
          </Row>

          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={12}>
              <h2 className='text-center mb-4'>{t('home.faq.title')}</h2>
              <Accordion>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>{t('home.faq.question1')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer1')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>{t('home.faq.question2')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer2')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>{t('home.faq.question3')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer3')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>{t('home.faq.question4')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer4')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>{t('home.faq.question5')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer5')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>{t('home.faq.question6')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer6')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>{t('home.faq.question7')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer7')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>{t('home.faq.question8')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer8')}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>{t('home.faq.question9')}</Accordion.Header>
                  <Accordion.Body className='bg-gray-200 fst-italic'>
                    {t('home.faq.answer9')}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>

      <section id='pricing'>
        <ProductsPricing onSelectProduct={selectProduct} />
      </section>

      <section id='contact'>
        <footer className='footer py-6 bg-primary text-white'>
          <Container>
            <Row>
              <Col md={4}>
                <h5>{t('home.footer.title')}</h5>
              </Col>
              <Col xs={6} md={2} className='mb-5 mb-lg-0'>
                <Image src='/medifyme-no-icon.png' alt='Logo' />
                <ul className='links-vertical mt-2'>
                  <li>
                    <Card.Link href='#'>{t('home.footer.link1')}</Card.Link>
                  </li>
                  <li>
                    <Card.Link href='#pricing'>{t('home.footer.link3')}</Card.Link>
                  </li>
                </ul>
              </Col>
              <Col xs={6} md={2} className='mb-5 mb-lg-0'></Col>
              <Col xs={12} md={4} className='mb-5 mb-lg-0'>
                <form action='#'>
                  <div className='form-row mb-2'>
                    <div className='col-12'>
                      {/* <input type='email' className='form-control mb-2' placeholder='example@company.com' name='email' aria-label='Subscribe form' required /> */}
                    </div>
                    <div className='col-12 d-grid gap-2'>
                      <a
                        href='mailto:info@medify.me'
                        target='_blank'
                        className='btn btn-secondary'
                        rel='noreferrer'
                      >
                        {t('home.footer.email')}
                      </a>
                    </div>
                  </div>
                </form>
                <p className='text-muted font-small m-0'>
                  {t('home.footer.privacy_text')}{' '}
                  <Card.Link
                    as={Link}
                    to={Routes.Privacy.path}
                    className='text-white'
                    href='#'
                    onClick={() => handleClickDoc('Privacy')}
                  >
                    {t('home.footer.privacy')}
                  </Card.Link>
                </p>
                <p className='text-muted font-small m-0'>
                  {t('home.footer.terms_text')}{' '}
                  <Card.Link
                    as={Link}
                    to={Routes.Terms.path}
                    className='text-white'
                    href='#'
                    onClick={() => handleClickDoc('Terms')}
                  >
                    {t('home.footer.terms')}
                  </Card.Link>
                </p>
                <p className='text-muted font-small m-0'>
                  <Card.Link
                    as={Link}
                    to={Routes.Disclaimers.path}
                    className='text-white'
                    href='#'
                    onClick={() => handleClickDoc('Disclaimer')}
                  >
                    {t('home.footer.disclaimers')}
                  </Card.Link>
                </p>
              </Col>
            </Row>
            <hr className='bg-gray-600 my-5' />
            <Row>
              <Col className='mb-md-2'>
                <Card.Link href='#home' className='d-flex justify-content-center'>
                  <Image
                    src='/logo-no-background.svg'
                    height={35}
                    className='d-block mx-auto mb-3'
                    alt='Medify.me Logo'
                  />
                </Card.Link>
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  role='contentinfo'
                >
                  <p className='font-weight-normal font-small mb-0'>
                    {t('home.footer.copyright')} © Medify.me 2023-
                    <span className='current-year'>{currentYear}</span>. {t('home.footer.rights')}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </section>
    </>
  );
}
