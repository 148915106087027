import React from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { useCreateHealthDataMutation } from '#store';
import '#atoms/survey/timeout';
import surveyJson from './survey.json';
import './survey.css';
import i18n from 'i18next';

export const HealthDataSurvey = ({ onComplete }) => {
  const [createHealthDataMutation] = useCreateHealthDataMutation();

  const userLanguage = i18n.language;

  const localizedSurveyJson = JSON.parse(JSON.stringify(surveyJson));

  const localizeSurvey = (json, lang) => {
    json.pages.forEach((page) => {
      page.elements.forEach((element) => {
        if (lang === 'pl') {
          if (element.title_pl) element.title = element.title_pl;
          if (element.choices) {
            element.choices.forEach((choice) => {
              if (choice.text_pl) {
                choice.text = choice.text_pl;
                choice.value = choice.text_pl;
              }
            });
          }
          if (element.noneText_pl) element.noneText = element.noneText_pl;
          if (element.otherText_pl) element.otherText = element.otherText_pl;
        } else if (lang === 'en') {
          if (element.choices) {
            element.choices.forEach((choice) => {
              choice.value = choice.text;
            });
          }
        }
      });
    });
    return json;
  };

  localizeSurvey(localizedSurveyJson, userLanguage);

  const survey = new Model(localizedSurveyJson);

  survey.showPageNumbers = false;
  survey.showQuestionNumbers = false;

  survey.onComplete.add(async ({ data }) => {
    const { data: createdDocument } = await createHealthDataMutation(data);

    // console.log('createdDocument: ', createdDocument);

    onComplete(createdDocument);
  });

  return (
    <>
      <Survey model={survey} />
    </>
  );
};
