import React from 'react';
import { useFetchMedicalDocumentsQuery } from '#store';
import { MedicalDocumentWidget } from '#atoms/MedicalDocumentWidget';
import { generatePath } from 'react-router-dom';
import { Routes } from '#Routes';
import { useTranslation } from 'react-i18next';

export const MedicalDocumentsListWidget = () => {
  const { t } = useTranslation();
  const query = {
    offset: 0,
    limit: 5,
  };

  const { data: medicalDocuments, isLoading } = useFetchMedicalDocumentsQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  const mappedDocuments =
    medicalDocuments?.results.map((document) => ({
      title: `${
        document.document_type === 'LAB' ? t('dashboard.laboratory-document') : t('dashboard.diagnosis-document')}`,
      id: document.id,
      startDate: document.date || document.created,
      link: generatePath(Routes.MedicalDocument.path, { id: document.id }),
      tags: document.tags,
    })) || [];

  return <MedicalDocumentWidget data={mappedDocuments} isLoading={isLoading} />;
};
