import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { usePDF, Margin } from 'react-to-pdf';
import { useNavigate } from 'react-router-dom';
import { useFetchMedicalDocumentQuery } from '#store';
import { MedicalDocumentActions, MedicalDocument } from '#components';
import { MEDICAL_DOCUMENT_ACTION_TYPES } from '#const';
import { Routes } from '#Routes';

const PDFOptions = {
  page: {
    margin: Margin.SMALL,
  },
};

const MedicalDocumentPage = ({
  documentActions = [
    MEDICAL_DOCUMENT_ACTION_TYPES.LIST,
    MEDICAL_DOCUMENT_ACTION_TYPES.EDIT,
    MEDICAL_DOCUMENT_ACTION_TYPES.DOWNLOAD,
    MEDICAL_DOCUMENT_ACTION_TYPES.CONFIRM,
    MEDICAL_DOCUMENT_ACTION_TYPES.PROCESS,
    MEDICAL_DOCUMENT_ACTION_TYPES.REPORT,
    MEDICAL_DOCUMENT_ACTION_TYPES.DELETE,
  ],
}) => {
  const params = useParams();
  // const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading } = useFetchMedicalDocumentQuery(
    { id: params?.id },
    { refetchOnMountOrArgChange: true },
  );

  const { toPDF, targetRef } = usePDF({
    filename: `MedicalDocument-${params?.id}.pdf`,
    ...PDFOptions,
  });

  const onMedicalDocumentDelete = () => {
    navigate(Routes.MedicalDocumentsList.path);
  };

  return (
    <>
      {isLoading ? (
        <div className='d-flex justify-content-center px-8 py-8'>
          <Spinner />
        </div>
      ) : (
        <Row className='justify-content-center mt-4'>
          <Col xs={12} xl={9} className='position-relative'>
            <div
              className='px-0 text-end position-absolute z-3'
              style={{ top: '-20px', right: '30px' }}
            >
              <MedicalDocumentActions
                actions={documentActions}
                displayButtons={true}
                MedicalDocument={data}
                callbacks={{
                  [MEDICAL_DOCUMENT_ACTION_TYPES.DOWNLOAD]: toPDF,
                  [MEDICAL_DOCUMENT_ACTION_TYPES.DELETE]: onMedicalDocumentDelete,
                }}
              />
            </div>

            <div ref={targetRef}>
              <MedicalDocument data={data} />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MedicalDocumentPage;
