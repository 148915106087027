import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import Headers from './Headers';
import ReactGA from 'react-ga4';
import { Router } from './Router';

import './scss/volt.scss';
import './index.css';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

function App() {
  useEffect(() => {
    // Force redirect to HTTPS
    if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }

    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <>
      <Headers />
      <RouterProvider router={Router} />
    </>
  );
}

export default App;
