import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const alert = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray',
    },
    buttonsStyling: false,
  }),
);
