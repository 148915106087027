import React from 'react';
import Pdf from '#assets/presentation.pdf';

export const PresentationPage = () => {
  window.location.href = Pdf;

  return (
    <div className='App'>
      <a href={Pdf} target='_blank' rel='noreferrer'>
        Download Pdf
      </a>
    </div>
  );
};


// history.push(Pdf);

export default PresentationPage;
