import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Routes } from '#Routes';
import { selectIsLogged, authLogin, setPatient } from '#store';

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLogged = useSelector(selectIsLogged);

  const redirectWhenLoggedIn = useCallback(() => {
    if (isLogged) navigate(Routes.Dashboard.path);
  }, [isLogged, navigate]);

  const login = useCallback(
    async (data) => {
      const response = await dispatch(authLogin(data));
      const { payload } = response;

      if (payload?.user) {
        dispatch(setPatient(payload.user));
        navigate(Routes.Dashboard.path);
      }

      return response;
    },
    [dispatch, navigate],
  );

  return {
    redirectWhenLoggedIn,
    login,
  };
};

export default useLogin;
