import React from 'react';
import 'survey-core/defaultV2.min.css';
import { HealthDataSurvey } from '#components';

const HealthDataSurveyPage = () => {
  return (
    <>
      <HealthDataSurvey />
    </>
  );
};

export default HealthDataSurveyPage;
