import React, { useState, useMemo, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Card,
  Form,
  InputGroup,
  Row,
  Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LineGraphChart } from './Charts';
import {
  EyeIcon,
  ChartBarIcon,
  TableIcon,
  SearchIcon,
  DocumentDownloadIcon,
} from '@heroicons/react/solid';
import { Pagination } from '#components';
import {
  MedicalDocumentsFilters,
  MEDICAL_DOCUMENTS_FILTERS_TYPES,
} from './MedicalDocumentsFilters';
import { usePDF, Margin } from 'react-to-pdf';
import { alert } from '#utils';

const LIMIT_PER_PAGE = 50;

const PDFOptions = {
  page: {
    margin: Margin.MEDIUM,
  },
  html2canvas: {
    scale: 1,
    useCORS: true,
    letterRendering: true,
  },
};

// Move getValue function here, before PDFTable
const getValue = (value) => {
  if (value.float_value !== null && value.float_value !== undefined) {
    return value.float_value;
  }
  if (value.string_value !== null && value.string_value !== undefined) {
    return value.string_value;
  }
  return 'N/A';
};

// Update formatCurrentDate to handle both display and filename formats
const formatCurrentDate = (forFilename = false) => {
  const date = new Date();
  if (forFilename) {
    // Format: YYYY-MM-DD
    return date.toISOString().split('T')[0];
  }
  // Format for display
  return date.toLocaleDateString();
};

// Now PDFTable can use getValue
const PDFTable = ({ data, selectedTags }) => {
  const { t } = useTranslation();

  // Determine which tags to display
  const tagsToDisplay =
    selectedTags.length > 0
      ? selectedTags
      : Array.from(new Set(data.flatMap((test) => test.allTags)));

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <h2 style={{ marginBottom: '10px', textAlign: 'center' }}>
          {t('laboratory-tests.pdf.pdf-title')}
        </h2>
        <div style={{ color: '#666', marginBottom: '10px' }}>
          {t('laboratory-tests.pdf.pdf-print-date')} {formatCurrentDate()}
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: '20px',
            borderBottom: '1px solid #dee2e6',
            paddingBottom: '10px',
          }}
        >
          <div style={{ fontWeight: 'bold', minWidth: '100px' }}>
            {t('laboratory-tests.table-head.tags')}:
          </div>
          <div>{tagsToDisplay.join(', ')}</div>
        </div>
      </div>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          pageBreakInside: 'avoid',
          borderSpacing: 0,
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: '16px 12px',
                height: '50px',
                backgroundColor: '#f8f9fa',
                borderBottom: '2px solid #dee2e6',
                textAlign: 'left',
              }}
            >
              {t('laboratory-tests.table-head.name')}
            </th>
            <th
              style={{
                padding: '16px 12px',
                height: '50px',
                backgroundColor: '#f8f9fa',
                borderBottom: '2px solid #dee2e6',
                textAlign: 'left',
              }}
            >
              {t('laboratory-tests.table-head.value')}
            </th>
            <th
              style={{
                padding: '16px 12px',
                height: '50px',
                backgroundColor: '#f8f9fa',
                borderBottom: '2px solid #dee2e6',
                textAlign: 'left',
              }}
            >
              {t('laboratory-tests.table-head.date')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((test) => (
            <tr
              key={`${test.key}-${test.type}`}
              style={{
                pageBreakInside: 'avoid',
                height: '45px',
                minHeight: '45px',
              }}
            >
              <td
                style={{
                  padding: '16px 12px',
                  borderBottom: '1px solid #dee2e6',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'middle',
                  lineHeight: '1.5',
                }}
              >
                {test.key}
              </td>
              <td
                style={{
                  padding: '16px 12px',
                  borderBottom: '1px solid #dee2e6',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'middle',
                  lineHeight: '1.5',
                }}
              >
                {getValue(test.latestValue)}
              </td>
              <td
                style={{
                  padding: '16px 12px',
                  borderBottom: '1px solid #dee2e6',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'middle',
                  lineHeight: '1.5',
                }}
              >
                {test.latestValue.date
                  ? new Date(test.latestValue.date).toLocaleDateString()
                  : 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const LaboratoryTestsList = ({ laboratoryTests = [], totalItems }) => {
  const { t } = useTranslation();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showStringHistoryModal, setShowStringHistoryModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const pageNumbers = Math.ceil(totalItems / LIMIT_PER_PAGE);
  const pages = Array.from(Array(pageNumbers).keys());

  const onPageChange = (p) => setCurrentPage(p);

  // Group and paginate the tests
  const paginatedTests = useMemo(() => {
    const groupedTests = laboratoryTests.reduce((acc, test) => {
      test.value.forEach((value) => {
        const valueType =
          value.float_value !== null && value.float_value !== undefined ? 'float' : 'string';
        const key = `${test.key}|${valueType}`;

        // Collect all unique tags from all values
        const allTags = test.value.reduce((tags, val) => {
          if (val.tags) {
            val.tags.forEach((tag) => tags.add(tag));
          }
          return tags;
        }, new Set());

        // If we have selected tags, only consider values that match the tags
        if (selectedTags.length > 0) {
          if (!value.tags || !selectedTags.some((tag) => value.tags.includes(tag))) {
            return;
          }
        }

        // Update the latest value logic to consider tags
        if (!acc[key]) {
          acc[key] = {
            key: test.key,
            type: valueType,
            valueType: valueType,
            latestValue: value,
            document_id: test.document_id,
            originalTest: test,
            code: value.code,
            allTags: Array.from(allTags),
            value: test.value,
          };
        } else if (new Date(value.date) > new Date(acc[key].latestValue.date)) {
          // Only update if the new value has a more recent date and matches tag criteria
          acc[key].latestValue = value;
        }
      });
      return acc;
    }, {});

    const filteredTests = Object.values(groupedTests).filter((test) => {
      const matchesSearch = test.key.toLowerCase().includes(searchTerm.toLowerCase());
      // We don't need to check tags here as it's already handled in the grouping logic
      return matchesSearch;
    });

    const sortedTests = filteredTests.sort((a, b) => {
      const keyComparison = a.key.localeCompare(b.key);
      if (keyComparison !== 0) return keyComparison;
      return a.type === 'float' ? -1 : 1;
    });
    return sortedTests;
  }, [laboratoryTests, searchTerm, selectedTags]);

  const currentPageTests = useMemo(() => {
    const start = currentPage * LIMIT_PER_PAGE;
    const end = start + LIMIT_PER_PAGE;
    return paginatedTests.slice(start, end);
  }, [paginatedTests, currentPage]);

  const handleShowHistory = (test) => {
    // Filter values based on selected tags if any tags are selected
    const filteredValues =
      selectedTags.length > 0
        ? test.value.filter((v) => v.tags && selectedTags.some((tag) => v.tags.includes(tag)))
        : test.value;

    if (test.type === 'float' && Array.isArray(filteredValues)) {
      const chartData = {
        key: test.key,
        label: test.key,
        values: filteredValues
          .map((v) => ({
            date: new Date(v.date),
            value: parseFloat(v.float_value),
            tags: v.tags || [], // Include tags for potential tooltip display
          }))
          .sort((a, b) => a.date - b.date)
          .map((v) => ({
            date: v.date.toLocaleDateString(),
            value: v.value,
            tags: v.tags,
          })),
      };
      setSelectedTest(chartData);
      setShowHistoryModal(true);
    } else if (test.type === 'string' && Array.isArray(filteredValues)) {
      const tableData = {
        key: test.key,
        values: filteredValues
          .map((v) => ({
            date: new Date(v.date),
            value: v.string_value,
            tags: v.tags || [],
          }))
          .sort((a, b) => b.date - a.date) // Sort in descending order
          .map((v) => ({
            date: v.date.toLocaleDateString(),
            value: v.value,
            tags: v.tags.join(', '), // Join tags for table display
          })),
      };
      setSelectedTest(tableData);
      setShowStringHistoryModal(true);
    }
  };

  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false);
    setShowStringHistoryModal(false);
    setSelectedTest(null);
  };

  // Reset current page when search term changes
  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const { toPDF, targetRef } = usePDF({
    filename: `${t('laboratory-tests.pdf.pdf-file-name', { date: formatCurrentDate(true) })}`,
    ...PDFOptions,
  });

  const handleDownload = async () => {
    alert
      .fire({
        title: t('laboratory-tests.pdf.pdf-generating'),
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        didOpen: async () => {
          alert.showLoading();
          await toPDF();
          alert.close();
        },
      })
      .then(() => {
        alert.fire({
          icon: 'success',
          title: t('laboratory-tests.pdf.pdf-generated-title'),
          text: t('laboratory-tests.pdf.pdf-generated-text'),
        });
      });
  };

  if (!laboratoryTests || laboratoryTests.length === 0) {
    return <div>{t('laboratory-tests.no-tests-found')}</div>;
  }

  const handleTagsChange = (filters) => {
    setSelectedTags(filters.tags || []);
    setCurrentPage(0); // Reset to first page when filters change
  };

  return (
    <>
      <Form className='mb-3'>
        <Row>
          <Col xs={12} sm={6} className='mb-2 mb-sm-0'>
            <MedicalDocumentsFilters
              filters={{ tags: selectedTags }}
              onChange={handleTagsChange}
              display={[MEDICAL_DOCUMENTS_FILTERS_TYPES.TAGS]}
            />
          </Col>
          <Col xs={12} sm={4} className='mb-2 mb-sm-0'>
            <InputGroup>
              <InputGroup.Text>
                <SearchIcon className='icon icon-xs' />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder={t('laboratory-tests.search-placeholder')}
                value={searchTerm}
                onChange={(e) => {
                  const newSearchTerm = e.target.value;
                  setSearchTerm(newSearchTerm);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={2} className='d-flex justify-content-end align-items-start'>
            <OverlayTrigger
              overlay={<Tooltip className='m-0'>{t('laboratory-tests.pdf.download-pdf')}</Tooltip>}
            >
              <Button variant='gray-800' onClick={handleDownload} className='h-auto py-2'>
                <DocumentDownloadIcon className='icon icon-xs' />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Form>

      {/* Update the PDF content div */}
      <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
        <div ref={targetRef} style={{ width: '800px', padding: '20px' }}>
          <PDFTable data={paginatedTests} selectedTags={selectedTags} />
        </div>
      </div>

      {/* Regular table display */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('laboratory-tests.table-head.name')}</th>
            <th>{t('laboratory-tests.table-head.value')}</th>
            <th>{t('laboratory-tests.table-head.date')}</th>
            <th>{t('laboratory-tests.table-head.tags')}</th>
            <th>{t('laboratory-tests.table-head.document')}</th>
            <th>{t('laboratory-tests.table-head.history')}</th>
          </tr>
        </thead>
        <tbody>
          {currentPageTests.map((test) => (
            <tr key={`${test.key}-${test.type}`}>
              <td>{test.key}</td>
              <td>{getValue(test.latestValue)}</td>
              <td>
                {test.latestValue.date
                  ? new Date(test.latestValue.date).toLocaleDateString()
                  : 'N/A'}
              </td>
              <td>
                {selectedTags.length > 0
                  ? test.allTags
                      .filter((tag) => selectedTags.includes(tag))
                      .map((tag, index) => (
                        <span key={index} className='badge bg-primary me-1'>
                          {tag}
                        </span>
                      ))
                  : test.allTags?.map((tag, index) => (
                      <span key={index} className='badge bg-primary me-1'>
                        {tag}
                      </span>
                    ))}
              </td>
              <td className='text-center'>
                {test.latestValue.document_id ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip className='m-0'>
                        {t('laboratory-tests.view-document-tooltip')}
                      </Tooltip>
                    }
                  >
                    <Link to={`/medical-documents/${test.latestValue.document_id}/edit`}>
                      <EyeIcon className='icon icon-xs text-gray-500' />
                    </Link>
                  </OverlayTrigger>
                ) : (
                  t('laboratory-tests.no-document')
                )}
              </td>
              <td className='text-center'>
                {(test.type === 'float' || test.type === 'string') && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip className='m-0'>
                        {t('laboratory-tests.view-history-tooltip')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant='link'
                      className='p-0'
                      onClick={() => handleShowHistory(test.originalTest)}
                    >
                      {test.type === 'float' ? (
                        <ChartBarIcon className='icon icon-xs text-gray-500' />
                      ) : (
                        <TableIcon className='icon icon-xs text-gray-500' />
                      )}
                    </Button>
                  </OverlayTrigger>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Card.Footer className='px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between'>
        <Pagination
          pages={pages}
          limit={LIMIT_PER_PAGE}
          pageNumbers={Math.ceil(paginatedTests.length / LIMIT_PER_PAGE)}
          totalItems={paginatedTests.length}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </Card.Footer>

      <Modal show={showHistoryModal} onHide={handleCloseHistoryModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('laboratory-tests.history-modal-title', { testName: selectedTest?.key })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedTest && <LineGraphChart data={[selectedTest]} />}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseHistoryModal}>
            {t('laboratory-tests.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showStringHistoryModal} onHide={handleCloseHistoryModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('laboratory-tests.history-modal-title', { testName: selectedTest?.key })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTest && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t('laboratory-tests.history-modal-date')}</th>
                  <th>{t('laboratory-tests.history-modal-value')}</th>
                  <th>{t('laboratory-tests.table-head.tags')}</th>
                </tr>
              </thead>
              <tbody>
                {selectedTest.values.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.value}</td>
                    <td>{item.tags}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseHistoryModal}>
            {t('laboratory-tests.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LaboratoryTestsList;
