import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TagsInput } from '#atoms';

export const MEDICAL_DOCUMENTS_FILTERS_TYPES = {
  TAGS: 'TAGS',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  CONFIRMED: 'CONFIRMED',
};

export const MedicalDocumentsFilters = ({ filters = {}, onChange, display = [] }) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm();

  const [documentType, setDocumentType] = useState(filters.document_type || '');
  const [confirmed, setConfirmed] = useState(filters.confirmed || '');
  const [tags, setTags] = useState([]);

  const returnFilters = (params) => {
    const filters = {
      document_type: params['documentType'] || documentType,
      confirmed,
      tags,
    };

    Object.keys(params).forEach((name) => {
      filters[name] = params[name];
    });

    onChange(filters);
  };

  const updateDocumentType = (e) => {
    setDocumentType(e.target.value);
    returnFilters({ document_type: e.target.value });
  };

  const updateConfirmed = (e) => {
    setConfirmed(e.target.value);
    returnFilters({ confirmed: e.target.value });
  };

  const tagsChange = (newTags) => {
    setTags(newTags);
    returnFilters({ tags: newTags });
  };

  return (
    <Form>
      <Row>
        {display.indexOf(MEDICAL_DOCUMENTS_FILTERS_TYPES.TAGS) >= 0 && (
          <Col className='mb-3 mb-sm-0' xs={12} sm={4} xl={3} style={{ minWidth: 260 }}>
            <TagsInput
              form={form}
              placeholder={t('medical-documents-list.filters-placeholders.tags')}
              inputPosition='top'
              initialValues={filters?.tags || []}
              onChange={tagsChange}
            />
          </Col>
        )}
        {display.indexOf(MEDICAL_DOCUMENTS_FILTERS_TYPES.DOCUMENT_TYPE) >= 0 && (
          <Col className='mb-3 mb-sm-0' xs={12} sm={4} xl={3} style={{ minWidth: 260 }}>
            <Form.Select value={documentType} className='d-inline' onChange={updateDocumentType}>
              <option value=''>{t('medical-documents-list.filters-placeholders.type')}</option>
              <option value='LAB'>{t('medical-documents-list.filters-placeholders.lab')}</option>
              <option value='DIAG'>{t('medical-documents-list.filters-placeholders.diag')}</option>
            </Form.Select>
          </Col>
        )}
        {display.indexOf(MEDICAL_DOCUMENTS_FILTERS_TYPES.CONFIRMED) >= 0 && (
          <Col xs={12} sm={4} xl={3} style={{ minWidth: 300 }}>
            <Form.Select value={confirmed} className='d-inline' onChange={updateConfirmed}>
              <option value=''>
                {t('medical-documents-list.filters-placeholders.confirmed-unconfirmed')}
              </option>
              <option value={true}>
                {t('medical-documents-list.filters-placeholders.confirmed')}
              </option>
              <option value={false}>
                {t('medical-documents-list.filters-placeholders.unconfirmed')}
              </option>
            </Form.Select>
          </Col>
        )}
      </Row>
    </Form>
  );
};
