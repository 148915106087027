import React, { useMemo } from 'react';
import { Image, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const ImagePreview = React.memo(function ImagePreview({ image, index, select, remove }) {
  const { t } = useTranslation();

  const imageBlob = useMemo(() => URL.createObjectURL(image), [image]);
  const isFilePDF = image.type.includes('pdf');

  return (
    <div className='image-preview'>
      {isFilePDF ? (
        <embed
          src={imageBlob + '#toolbar=0&navpanes=0'} // hide pdf viewer toolbar
          className='image-preview__element'
        />
      ) : (
        <Image src={imageBlob} className='image-preview__element' />
      )}
      <div className='image-preview__actions'>
        <Button size='sm' variant='danger' onClick={() => remove(index)}>
          {t('forms.image.remove')}
        </Button>
        {!isFilePDF && (
          <Button size='sm' variant='primary' onClick={() => select(index)}>
            {t('forms.image.edit')}
          </Button>
        )}
      </div>
    </div>
  );
});
