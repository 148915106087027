import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useFetchStripeCheckoutMutation, useConsentConsumerRightsMutation } from '#store';
import SubscriptionCheckoutModal from './SubscriptionCheckoutModal';

const STRIPE_TEST_PUBLIC_KEY = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;

const SubscriptionCheckout = ({ product, onUnselectProduct }) => {
  const [paymentCheckout] = useFetchStripeCheckoutMutation();
  const [consentConsumerRights] = useConsentConsumerRightsMutation();

  const handlePayment = useCallback(
    async ({ consent }) => {
      if (!consent) return;

      const { price_id } = product;

      if (!price_id) {
        console.error('Stripe price id not found.');
      }

      try {
        // run parallel consent and stripe loading
        const consentLoading = consentConsumerRights(consent);
        const stripeLoading = loadStripe(STRIPE_TEST_PUBLIC_KEY);

        const stripe = await stripeLoading;
        const consentResult = await consentLoading;

        if (!consentResult?.data?.consent) return;

        const { data } = await paymentCheckout({ price_id });

        if (data.session_id) {
          await stripe.redirectToCheckout({ sessionId: data.session_id });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [consentConsumerRights, paymentCheckout, product],
  );

  return (
    <SubscriptionCheckoutModal
      onUnselectProduct={onUnselectProduct}
      onPayment={handlePayment}
      product={product}
    />
  );
};

export default React.memo(SubscriptionCheckout);
