import React from 'react';
import { Button, ButtonGroup, Card, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import {
  BadgeCheckIcon,
  CloudIcon,
  EyeIcon,
  FolderDownloadIcon,
  PencilAltIcon,
  TrashIcon,
  AcademicCapIcon,
  CollectionIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';
import { Routes } from '#Routes';
import { alert } from '#utils';
import {
  useConfirmMedicalDocumentMutation,
  useProcessMedicalDocumentMutation,
  useReportMedicalDocumentMutation,
  useDeleteMedicalDocumentMutation,
  useFetchSubscriptionStatusQuery,
} from '#store';
import { MEDICAL_DOCUMENT_ACTION_TYPES } from '#const';

export const MedicalDocumentActions = ({
  actions,
  displayButtons = false,
  MedicalDocument: data,
  callbacks,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [confirmMedicalDocumentMutation] = useConfirmMedicalDocumentMutation();
  const [processMedicalDocumentMutation] = useProcessMedicalDocumentMutation();
  const [reportMedicalDocumentMutation] = useReportMedicalDocumentMutation();
  const [deleteMedicalDocumentMutation] = useDeleteMedicalDocumentMutation();

  const { data: subscriptionStatus } = useFetchSubscriptionStatusQuery();

  const noCreditsAlert = () => {
    const title =
      subscriptionStatus?.currentSubs?.length > 0
        ? t('medical-document-actions.not-enough-credits')
        : t('medical-document-actions.no-active-subscription');
    const text =
      subscriptionStatus?.currentSubs?.length > 0
        ? t('medical-document-actions.used-all-credits')
        : t('medical-document-actions.no-active-subscription-2');

    alert
      .fire({
        icon: 'warning',
        title,
        text,
        showCancelButton: true,
        confirmButtonText: t('medical-document-actions.go-to-sub-page'),
        cancelButtonText: t('medical-document-actions.not-now'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate(Routes.AccountSubscribe.path);
        }
      });
  };

  const processingInProgressAlert = () => {
    alert.fire({
      icon: 'warning',
      title: t('medical-document-actions.process-in-progress-title'),
      text: t('medical-document-actions.process-in-progress-text'),
      confirmButtonText: 'Ok',
    });
  };

  const confirmDocument = async () => {
    if (data.confirmed) return;

    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        icon: 'question',
        title: t('medical-document-actions.confirm-approving-title'),
        text: t('medical-document-actions.confirm-approving-text'),
        showCancelButton: true,
        confirmButtonText: t('medical-document-actions.confirm-button'),
        cancelButtonText: t('medical-document-actions.cancel-button'),
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert.fire({
            title: t('medical-document-actions.confirming-document'),
            didOpen: () => alert.showLoading(),
          });

          const { error } = await confirmMedicalDocumentMutation({ uuid: data.uuid });

          if (error) {
            return alert.fire({
              icon: 'error',
              title: t('medical-document-actions.error'),
              text: error.data?.message || t('medical-document-actions.confirming-error'),
            });
          }

          return alert.fire({
            icon: 'success',
            title: t('medical-document-actions.document-confirmed-title'),
            text: t('medical-document-actions.document-confirmed-text'),
          });
        }
      });
  };

  const deleteDocument = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        icon: 'warning',
        title: t('medical-document-actions.confirm-deleting-title'),
        text: t('medical-document-actions.confirm-deleting-text'),
        showCancelButton: true,
        confirmButtonText: t('medical-document-actions.confirm-button'),
        cancelButtonText: t('medical-document-actions.cancel-button'),
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert.fire({
            title: t('medical-document-actions.deleting-document'),
            didOpen: () => alert.showLoading(),
          });

          const { error } = await deleteMedicalDocumentMutation({ id: data.id });

          if (error) {
            return alert.fire({
              icon: 'error',
              title: t('medical-document-actions.error'),
              text:
                error.data?.message || // e.g. "Cannot delete this document, it has been used in health report."
                t('medical-document-actions.deleting-error'),
            });
          }

          return alert
            .fire({
              icon: 'success',
              title: t('medical-document-actions.document-deleted-title'),
              text: t('medical-document-actions.document-deleted-text'),
            })
            .then(callbacks && callbacks[MEDICAL_DOCUMENT_ACTION_TYPES.DELETE]?.());
        }
      });
  };

  const downloadDocument = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        title: t('medical-document-actions.pdf-generating'),
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        didOpen: async () => {
          alert.showLoading();
          await callbacks[MEDICAL_DOCUMENT_ACTION_TYPES.DOWNLOAD]?.();
          alert.close();
        },
      })
      .then(() => {
        return alert.fire({
          icon: 'success',
          title: t('medical-document-actions.pdf-generated-title'),
          text: t('medical-document-actions.pdf-generated-text'),
        });
      });
  };

  const processDocument = async () => {
    const remainingCredits =
      subscriptionStatus.creds + subscriptionStatus.extra_credits - subscriptionStatus.counts;

    if (remainingCredits <= 0) {
      return noCreditsAlert();
    }

    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    if (data.process_status === 'FINISHED') {
      return alert.fire({
        icon: 'warning',
        title: t('medical-document-actions.document-processed-title'),
        text: t('medical-document-actions.document-processed-text'),
      });
    }

    return alert
      .fire({
        icon: 'info',
        title: t('medical-document-actions.confirm-processing-title'),
        text: `${t('medical-document-actions.confirm-processing-text1')} ${remainingCredits}
          ${t('medical-document-actions.confirm-processing-text2')}`,
        showCancelButton: true,
        confirmButtonText: t('medical-document-actions.confirm-button'),
        cancelButtonText: t('medical-document-actions.cancel-button'),
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const { error } = await processMedicalDocumentMutation({ uuid: data.uuid });

          if (error) {
            if (error.status === 402) return noCreditsAlert();

            return alert.fire({
              icon: 'error',
              title: t('medical-document-actions.error'),
              text: error.data?.message || t('medical-document-actions.document-processing-error'),
            });
          }

          return alert.fire({
            icon: 'info',
            title: t('medical-document-actions.document-processing-title'),
            text: t('medical-document-actions.document-processing-text'),
          });
        }
      });
  };

  const generateReport = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    if (data.report_status === 'IN_PROGRESS') {
      return alert.fire({
        icon: 'warning',
        title: t('medical-document-actions.ai-generation-title'),
        text: t('medical-document-actions.ai-generation-text'),
        confirmButtonText: 'Ok',
      });
    }

    if (subscriptionStatus?.creds + subscriptionStatus?.extra_credits === 0) {
      return noCreditsAlert();
    }

    if (!data.confirmed) {
      return alert.fire({
        icon: 'warning',
        title: t('medical-document-actions.document-content-not-confirmed-title'),
        text: t('medical-document-actions.document-content-not-confirmed-text'),
        confirmButtonText: t('medical-document-actions.document-content-not-confirmed-button'),
      });
    }

    return alert
      .fire({
        icon: 'info',
        title:
          data.report_status === 'FINISHED'
            ? t('medical-document-actions.ai-report-generated-title1')
            : t('medical-document-actions.ai-report-generated-title2'),
        text: `${
          data.report_status === 'FINISHED'
            ? t('medical-document-actions.ai-report-generated-text1')
            : t('medical-document-actions.ai-report-generated-text2')
        } ${t('medical-document-actions.ai-report-generated-text3')} ${subscriptionStatus.creds}
          ${t('medical-document-actions.ai-report-generated-text4')}`,
        showCancelButton: true,
        confirmButtonText: t('medical-document-actions.confirm-button'),
        cancelButtonText: t('medical-document-actions.cancel-button'),
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const { error } = await reportMedicalDocumentMutation({ uuid: data.uuid });

          if (error) {
            if (error.status === 402) return noCreditsAlert();

            return alert.fire({
              icon: 'error',
              title: t('medical-document-actions.error'),
              text: error.data?.message || t('medical-document-actions.document-report-error-text'),
            });
          }
          return alert.fire({
            icon: 'info',
            title: t('medical-document-actions.document-report-title'),
            text: t('medical-document-actions.document-report-text'),
          });
        }
      });
  };

  const editDocument = async () => {
    navigate(generatePath(Routes.MedicalDocumentEdit.path, { id: data.id }));
  };

  const viewDocument = async () => {
    navigate(generatePath(Routes.MedicalDocument.path, { id: data.id }));
  };

  const viewDocumentsList = () => {
    navigate(Routes.MedicalDocumentsList.path);
  };

  const CTA = displayButtons ? Button : Card.Link;

  const actionsMarkup = {
    [MEDICAL_DOCUMENT_ACTION_TYPES.CONFIRM]: (
      <OverlayTrigger
        overlay={
          <Tooltip className='m-0'>
            {data.confirmed
              ? t('medical-document-actions.document-confirmed')
              : t('medical-document-actions.document-confirm')}
          </Tooltip>
        }
      >
        <CTA
          variant={data.confirmed ? 'success' : 'gray-800'}
          className='ms-2'
          onClick={() => confirmDocument()}
        >
          <BadgeCheckIcon
            className={
              !displayButtons && data.confirmed
                ? 'icon icon-xs text-success'
                : 'icon icon-xs text-white'
            }
          />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.DELETE]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.delete')}</Tooltip>}
      >
        <CTA variant='danger' className='ms-2' onClick={() => deleteDocument()}>
          <TrashIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'danger'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.DOWNLOAD]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.download')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => downloadDocument()}>
          <FolderDownloadIcon
            className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`}
          />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.EDIT]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.edit')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => editDocument()}>
          <PencilAltIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.LIST]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.documents-list')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => viewDocumentsList()}>
          <CollectionIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.PROCESS]: (
      <OverlayTrigger
        overlay={
          <Tooltip className='m-0'>
            {data.process_status === 'IN_PROGRESS' ? (
              <>
                <Spinner className='me-2' style={{ width: '16px', height: '16px' }} />
                <span>Process Status</span>
              </>
            ) : (
              t('medical-document-actions.process')
            )}
          </Tooltip>
        }
      >
        <CTA variant='info' className='ms-2' onClick={() => processDocument()}>
          <CloudIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'info'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.REPORT]: (
      <OverlayTrigger
        overlay={
          <Tooltip className='m-0'>
            {data.report_status === 'IN_PROGRESS' ? (
              <>
                <Spinner className='me-2' style={{ width: '16px', height: '16px' }} />
                <span>Preparing AI report</span>
              </>
            ) : (
              t('medical-document-actions.create-report')
            )}
          </Tooltip>
        }
      >
        <CTA variant='info' className='ms-2' onClick={() => generateReport()}>
          <AcademicCapIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'info'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.VIEW]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.view')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => viewDocument()}>
          <EyeIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`} />
        </CTA>
      </OverlayTrigger>
    ),
  };

  return (
    data && (
      <ButtonGroup>
        {actions.map((action) => (
          <div key={action}>{actionsMarkup[action]}</div>
        ))}
      </ButtonGroup>
    )
  );
};
