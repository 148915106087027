import React, { useMemo, useState } from 'react';
import { Card, Row, Col, Button, Spinner, Collapse, Modal, Form } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useFetchMedicalDocumentsQuery,
  useFetchHealthDataQuery,
  useCreateHealthReportMutation,
  useFetchSubscriptionStatusQuery,
} from '#store';
import {
  MedicalDocumentsList,
  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS,
  MedicalDocumentsFilters,
  MEDICAL_DOCUMENTS_FILTERS_TYPES,
  MedicalDocumentsGallery,
  Pagination,
  HealthDataSurvey,
} from '#components';
import { Routes } from '#Routes';
import { alert } from '#utils';
import './styles.scss';

const LIMIT_PER_PAGE = 5;
const MAX_SELECTION_NUMBER = 5;

const HealthReportCreatePage = ({ limit = LIMIT_PER_PAGE }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [healthInterview, setHealthInterview] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createHealthReportMutation] = useCreateHealthReportMutation();
  const { data: subscriptionStatus } = useFetchSubscriptionStatusQuery();

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const offset = currentPage * limit;

  const query = useMemo(() => {
    const query = {
      offset,
      limit,
      confirmed: true,
    };
    if (filters.tags?.length > 0) query.tags = filters.tags;
    if (filters.document_type) query.document_type = filters.document_type;
    if (filters.confirmed) query.confirmed = filters.confirmed;

    return query;
  }, [filters]);

  const { data: medicalDocumentsAll, isLoading: isLoadingMedicalDocumentsAll } =
    useFetchMedicalDocumentsQuery(
      {
        offset,
        limit,
        confirmed: true,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: medicalDocuments, isLoading: isLoadingMedicalDocuments } =
    useFetchMedicalDocumentsQuery(query, {
      refetchOnMountOrArgChange: true,
    });

  const { data: healthData, isLoading: isLoadingHealthData } = useFetchHealthDataQuery(
    { limit: 1 },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const remainingCredits =
    subscriptionStatus?.creds + subscriptionStatus?.extra_credits - subscriptionStatus?.counts;

  const onSelectionChange = ({ id, checked }) => {
    if (checked) {
      setSelectedDocuments([
        ...selectedDocuments,
        medicalDocuments.results.find((doc) => doc.id === id),
      ]);
    } else {
      removeSelected({ id });
    }
  };

  const removeSelected = ({ id }) => {
    selectedDocuments.splice(
      selectedDocuments.find((doc) => doc.id === id),
      1,
    );
    setSelectedDocuments([...selectedDocuments]);
  };

  const onNewFilters = (newFilters) => {
    const params = { ...query, ...newFilters };
    setFilters(params);
  };

  const onTagClick = (tag) => {
    const params = { ...query };
    if (params.tags) {
      if (!params.tags.find((t) => t === tag)) {
        params.tags = [...params.tags, tag];
      }
    } else {
      params.tags = [tag];
    }
    setFilters(params);
  };

  const totalItems = medicalDocuments?.count ?? 0;

  const pageNumbers = Math.ceil(totalItems / limit);

  const pages = Array.from(Array(pageNumbers).keys());

  const onPageChange = (p) => setCurrentPage(p);

  const onHealthInteviewComplete = (interview) => {
    setHealthInterview(interview);
    closeModal();
  };

  const submitHealthReport = async () => {
    if (remainingCredits > 0) {
      console.log('Submit Health Report: ', {
        documents: selectedDocuments,
        health_interview: healthInterview.id,
        question: question,
      });

      const response = await createHealthReportMutation({
        documents: selectedDocuments.map((doc) => doc.id),
        health_interview: healthInterview.id,
        question: question,
      });

      if (response) {
        alert
          .fire({
            icon: t('health-report-create-page.sucess-icon'),
            title: t('health-report-create-page.sucess-title'),
            text: t('health-report-create-page.sucess-text'),
            confirmButtonText: t('health-report-create-page.sucess-confirm-button'),
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              navigate(Routes.HealthReportsList.path);
            }
          });
      } else {
        alert.fire({
          icon: t('health-report-create-page.error-icon'),
          title: t('health-report-create-page.error-title'),
          text: t('health-report-create-page.error-text'),
          confirmButtonText: t('health-report-create-page.error-confirm-button'),
        });
      }
    } else {
      alert
        .fire({
          icon: 'warning',
          title: t('health-report-create-page.actve-subscription-title'),
          text: t('health-report-create-page.actve-subscription-text'),
          confirmButtonText: t('health-report-create-page.actve-subscription-button'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            navigate(Routes.AccountSubscribe.path);
          }
        });
    }
  };

  const documentsList = (
    <>
      <Col sm={12}>
        <Card className='mb-4'>
          <Card.Header>
            <h3 className='text-center'>
              {t('health-report-create-page.select-documents-header')}
            </h3>
            <p className='text-center'>
              {t('health-report-create-page.select-documents-text1')}
              {MAX_SELECTION_NUMBER}
              {t('health-report-create-page.select-documents-text2')}
            </p>
          </Card.Header>
          <Card.Body>
            <div className='table-settings mb-3'>
              <Row className='justify-content-between align-items-center'>
                <Col xs={12}>
                  <MedicalDocumentsFilters
                    onChange={onNewFilters}
                    filters={filters}
                    display={[
                      MEDICAL_DOCUMENTS_FILTERS_TYPES.TAGS,
                      MEDICAL_DOCUMENTS_FILTERS_TYPES.DOCUMENT_TYPE,
                    ]}
                  />
                </Col>
              </Row>
            </div>
            {isLoadingMedicalDocuments ? (
              <div className='d-flex justify-content-center px-8 py-8'>
                <Spinner style={{ width: '30px', height: '30px' }} />
              </div>
            ) : (
              <MedicalDocumentsList
                data={medicalDocuments}
                selectedDocuments={selectedDocuments.map((doc) => doc.id)}
                onSelectionChange={onSelectionChange}
                maxSelectionNumber={MAX_SELECTION_NUMBER}
                display={[
                  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT,
                  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.INFO,
                  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.CREATED,
                  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.DOCUMENT_DATE,
                  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.DOCUMENT_TYPE,
                ]}
                onTagClick={onTagClick}
              />
            )}
            <Pagination
              pages={pages}
              limit={LIMIT_PER_PAGE}
              pageNumbers={pageNumbers}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col sm={12} className='mb-1'>
        <MedicalDocumentsGallery data={selectedDocuments} onRemove={removeSelected} hideAI={true} />
      </Col>
    </>
  );

  const interviewSelect = (
    <Col sm={12}>
      <Card className='mb-4'>
        <Card.Header>
          <h3 className='text-center'>{t('health-report-create-page.health-interview-header')}</h3>
          <p className='text-center'>{t('health-report-create-page.health-interview-text')}</p>
        </Card.Header>
        <Card.Body>
          <Row className='justify-content-center'>
            {healthData?.results?.length > 0 && (
              <Col sm={6} className='mb-3 mb-sm-0'>
                <div className='d-grid'>
                  <Button
                    variant={healthInterview.id ? 'primary' : 'outline-primary'}
                    size='lg'
                    onClick={() => {
                      setHealthInterview(healthData.results[0]);
                    }}
                  >
                    <div>{t('health-report-create-page.health-interview-latest')}</div>
                    <small style={{ fontSize: '12px' }}>
                      <>{moment(healthData.results[0].created_at).format('y-MM-DD HH:mm')}</>
                    </small>
                  </Button>
                </div>
              </Col>
            )}
            <Col sm={6}>
              <div className='d-grid h-100'>
                <Button variant='outline-primary' size='lg' onClick={openModal}>
                  {healthData?.results?.length > 0
                    ? t('health-report-create-page.health-interview-update')
                    : t('health-report-create-page.health-interview-create')}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <>
      {isLoadingMedicalDocumentsAll ? (
        <Spinner></Spinner>
      ) : medicalDocumentsAll?.results?.length > 0 ? (
        <Row>
          {interviewSelect}
          <Collapse in={!!healthInterview.id}>
            <div>{documentsList}</div>
          </Collapse>
          <Collapse in={selectedDocuments?.length > 0}>
            <Col sm={12}>
              <div className='d-grid'>
                <Button
                  variant='primary'
                  size='lg'
                  className='animate-up-2'
                  onClick={submitHealthReport}
                >
                  {t('health-report-create-page.health-report-create-button')}
                </Button>
              </div>
            </Col>
          </Collapse>
        </Row>
      ) : (
        <Card>
          <Card.Header className='text-center'>
            {t('health-report-create-page.health-report-create-text')}
          </Card.Header>
        </Card>
      )}

      <Modal
        size={'xl'}
        show={showModal}
        onHide={closeModal}
        backdrop='static'
        backdropClassName='health-report-backdrop'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('health-report-create-page.health-interview-header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HealthDataSurvey onComplete={onHealthInteviewComplete} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(HealthReportCreatePage);
