export const Languages = [
  {
    value: 1,
    name: 'POLSKI',
  },
  {
    value: 2,
    name: 'ENGLISH',
  },
];
