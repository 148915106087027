import React from 'react';
import { Button, FormCheck, Card, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const SubscriptionCheckoutModal = ({ onUnselectProduct, onPayment, product }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  if (!product) return;

  const { name, price, currency, periodType } = product;

  const nextPayment = moment()
    .add(1, periodType === 'year' ? 'years' : 'months')
    .format('DD MMMM YYYY');

  const tag = t(`products.plan.${name}.tag`);

  return (
    <Modal
      centered
      size='lg'
      className='modal-tertiary text-white'
      show={Boolean(product)}
      onHide={onUnselectProduct}
    >
      <Form onSubmit={handleSubmit(onPayment)}>
        <Modal.Body className='text-center pt-5'>
          <h2 className='display-3'>
            {t(`products.plan.${name}.title`)}{' '}
            {tag && (
              <span className='pro-badge subscription-badge bg-secondary fw-bolder'>{tag}</span>
            )}
          </h2>
          <p className='mt-4'>
            {t('subscription.next-payment')}{' '}
            <span className='fw-bold'>
              {`${price / 100} ${currency}`} / {t(`periods.${periodType}`).toLowerCase()}
            </span>
            {` ${t('subscription.will-expire').toLowerCase()} ${nextPayment}.`}
          </p>
        </Modal.Body>
        <Modal.Footer className='flex justify-content-center mx-lg-5'>
          <div className='mb-4'>
            <Button
              as={Link}
              variant='outline-white'
              size='md'
              className='me-2'
              onClick={onUnselectProduct}
            >
              {t(`subscription.change-plan`)}
            </Button>
            <Button
              variant='secondary'
              size='md'
              className='px-4'
              type='submit'
              disabled={isSubmitting}
            >
              {t(`subscription.payment`)}
            </Button>
          </div>
          <FormCheck type='checkbox' className='mb-4'>
            <FormCheck.Input required id='consumer-rights' {...register('consent')} />
            <FormCheck.Label htmlFor='consumer-rights' className='fw-normal mb-0'>
              <Card.Text className='font-small'>{t('consent.consumer-rights')}</Card.Text>
            </FormCheck.Label>
          </FormCheck>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default React.memo(SubscriptionCheckoutModal);
