import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TagsInput } from '#atoms';
import { Languages } from '#const';

export function DocumentInfoForm({ form, data }) {
  const { register } = form;
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col>
          <Card className='mb-4'>
            <Card.Header className='text-center'>
              <h4>Podaj informacje ogólne</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} className='mb-3'>
                  <TagsInput form={form} initialValues={data?.tags} title={t('lab.tags')} />
                </Col>
                <Col xs={12} className='mb-3'>
                  <Form.Group id='language'>
                    <Form.Label>{t('lab.language')}</Form.Label>
                    <Form.Select
                      defaultValue='0'
                      className='mb-0'
                      name='lab'
                      {...register('language', { required: true })}
                    >
                      {Languages.map((lang, index) => (
                        <option key={`lang_${index}`} value={lang.value}>
                          {lang.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} className='mb-3'>
                  <Form.Group id='date'>
                    <Form.Label>{t('lab.date')}</Form.Label>
                    <Form.Control
                      required
                      type='date'
                      id='lab-date'
                      name='lab-date'
                      {...register('date', { required: true })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className='mb-3'>
                  <Form.Group id='note'>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      id='lab-note'
                      name='lab-note'
                      {...register('notes', { required: false })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
