import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Routes } from '#Routes';
import { authRegister, setPatient } from '#store';

const useRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCallback(
    async (data) => {
      const response = await dispatch(authRegister(data));
      const { payload } = response;

      if (payload?.user) {
        dispatch(setPatient(payload.user));

        // navigate to profile page after registration
        const timeoutId = setTimeout(() => {
          navigate(Routes.Dashboard.path);
        }, 5000);

        return () => {
          clearTimeout(timeoutId);
        };
      }

      return response;
    },
    [dispatch, navigate],
  );
};

export default useRegister;
