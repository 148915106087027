import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';
import {
  // ArchiveIcon,
  // CalendarIcon,
  ChartBarIcon,
  // ChartPieIcon,
  ChevronRightIcon,
  // ClipboardListIcon,
  // CogIcon,
  CreditCardIcon,
  // InboxIcon,
  InformationCircleIcon,
  // LocationMarkerIcon,
  // NewspaperIcon,
  TableIcon,
  // TemplateIcon,
  // UsersIcon,
  // ViewGridIcon,
  CashIcon,
  XIcon,
  ExclamationCircleIcon,
  FlagIcon,
  MailIcon,
  PlusSmIcon,
  ViewListIcon,
} from '@heroicons/react/solid';
import { LogoutIcon, HomeIcon } from '@heroicons/react/outline';
import { Nav, Badge, Image, Button, Dropdown, Navbar, Collapse, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '#hooks';
import UnitedKingdomFlag from '#assets/img/flags/gb.svg';
import PlFlag from '#assets/img/flags/pl.svg';

import i18n from '#i18n/config';
import { useTranslation } from 'react-i18next';
import { Routes } from '#Routes';

export const Sidebar = (props = {}) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const contracted = props.contracted ? 'contracted' : '';
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);
  // const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  // const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some((item) => item.includes(itemKey));
    const newCollapsedItems = isCollapsed
      ? collapsedItems.filter((item) => !item.includes(itemKey))
      : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  const events = isMobile ? {} : {}; // { onMouseEnter, onMouseLeave };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon: NavItemIcon, children = null } = props;
    const isOpened = collapsedItems.some((item) => item.includes(eventKey));

    return (
      <Nav.Item>
        <Nav.Link
          onClick={() => onNavItemCollapse(eventKey)}
          aria-expanded={isOpened}
          aria-controls={eventKey}
          className='d-flex justify-content-between align-items-center'
        >
          <span>
            <span className='sidebar-icon'>
              <NavItemIcon className='icon icon-xs me-2' />
            </span>
            <span className='sidebar-text'>{title}</span>
          </span>
          <span className='link-arrow'>
            <ChevronRightIcon className='icon icon-sm' />
          </span>
        </Nav.Link>
        <Collapse in={isOpened} className='multi-level'>
          <div id={eventKey}>{children}</div>
        </Collapse>
      </Nav.Item>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      onClick,
      link,
      target,
      icon: NavItemIcon,
      image,
      badgeText,
      badgeBg,
      badgeColor = 'white',
    } = props;
    const classNames = badgeText ? 'd-flex align-items-center justify-content-between' : '';
    const navItemClassName = link === pathname ? 'active' : '';

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link
          as={Link}
          to={link ?? ''}
          target={target}
          className={classNames}
          onClick={onClick}
        >
          <span>
            {NavItemIcon && (
              <span className='sidebar-icon'>
                <NavItemIcon className='icon icon-xs me-2' />
              </span>
            )}

            {image ? (
              <Image src={image} width={20} height={20} className='sidebar-icon svg-icon' />
            ) : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className='sidebar-text-contracted'>{title[0]}</span>
            ) : null}

            <span className='sidebar-text'>{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className='badge-sm notification-count'>
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  function Language() {
    const changeLanguage = (lang, event) => {
      i18n.changeLanguage(lang);
      event.preventDefault();
    };

    return (
      <>
        <CollapsableNavItem eventKey='tables/' title={t('sidebar.languages')} icon={FlagIcon}>
          <NavItem
            title={t('languages.pl')}
            onClick={(e) => changeLanguage('pl', e)}
            image={PlFlag}
          />
          <NavItem
            title={t('languages.en')}
            onClick={(e) => changeLanguage('en', e)}
            image={UnitedKingdomFlag}
          />
        </CollapsableNavItem>
      </>
    );
  }

  return (
    <>
      <Navbar
        as={Col}
        xs={12}
        expand={false}
        collapseOnSelect
        variant='dark'
        className='navbar-theme-primary px-4 d-md-none'
      >
        <Navbar.Brand to={'/a'} className='me-lg-5'>
          {/* <Image src={ReactHero} className="navbar-brand-dark" /> */}
        </Navbar.Brand>
        <div className='d-flex align-items-center'>
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className='navbar-toggler-icon' />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames='sidebar-transition'>
        <SimpleBar
          {...events}
          className={`${contracted} ${showClass} sidebar d-md-block bg-gray-800 text-white collapse`}
        >
          <div className='sidebar-inner px-4 pt-3'>
            <div className='user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4'>
              {/* <div className="d-flex align-items-center">
                <div className="avatar-lg me-4">
                  <Image
                    src={""}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h5 className="mb-3">Hi, Jane</h5>
                  <Button
                    variant="secondary"
                    size="sm"
                    to={"/#"}
                    className="d-inline-flex align-items-center"
                  >
                    <LogoutIcon className="icon icon-xxs me-1" /> Sign Out
                  </Button>
                </div>
              </div> */}
              <Nav.Link className='collapse-close d-md-none' onClick={onCollapse}>
                <XIcon className='icon icon-xs' />
              </Nav.Link>
            </div>
            <Nav className='flex-column pt-3 pt-md-0'>
              <NavItem title='Home' link={'/#'} icon={HomeIcon} />

              <NavItem
                title={t('sidebar.items.dashboard')}
                icon={ChartBarIcon}
                link={Routes.Dashboard.path}
              />
              <NavItem
                title={t('sidebar.items.documents')}
                link={'/medical-documents'}
                icon={ChartBarIcon}
              />
              <NavItem
                title={t('sidebar.items.ai_health_reports')}
                link={'/health-reports'}
                icon={ChartBarIcon}
              />
              <NavItem title={t('sidebar.items.laboratory-tests')} link={'/laboratory-tests'} icon={ChartBarIcon} />
              <Dropdown.Divider className='my-3 border-indigo' />
              <NavItem
                title={t('sidebar.items.subscribe')}
                icon={CreditCardIcon}
                link={Routes.AccountSubscribe.path}
              />
              <NavItem
                title={t('sidebar.items.account')}
                icon={CashIcon}
                link={Routes.Account.path}
              />
              <NavItem
                title={t('sidebar.items.disclaimers')}
                icon={ExclamationCircleIcon}
                link={Routes.Disclaimers.path}
              />
              <NavItem
                title={t('sidebar.items.terms')}
                icon={InformationCircleIcon}
                link={Routes.Terms.path}
              />
              <NavItem
                title={t('sidebar.items.privacy')}
                icon={InformationCircleIcon}
                link={Routes.Privacy.path}
              />

              <NavItem
                title={t('sidebar.items.contact')}
                icon={MailIcon}
                link={Routes.Contact.path}
              />
              <Language />

              <NavItem
                title={t('sidebar.items.logout')}
                icon={LogoutIcon}
                link={'/#'}
                onClick={logout}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
