import React from 'react';
import { Pagination as PaginationBS } from 'react-bootstrap';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';

const LIMIT_PER_PAGE = 5;

export const Pagination = ({
  pages,
  limit = LIMIT_PER_PAGE,
  pageNumbers,
  totalItems,
  currentPage,
  onPageChange,
}) => {
  const onNextPage = () => {
    onPageChange(currentPage + 1);
  };
  const onPrevPage = () => {
    onPageChange(Math.max(currentPage - 1, 0));
  };

  const indexes = [
    ...new Set([
      0,
      Math.max(currentPage - 1, 0) - 1 > 0 ? 'spacer1' : -1,
      Math.max(currentPage - 1, 0),
      currentPage,
      Math.min(pageNumbers - 1, currentPage + 1),
      Math.min(pageNumbers - 1, currentPage + 1) + 1 < pageNumbers ? 'spacer2' : -1,
      pageNumbers - 1,
    ]),
  ];

  const pagesByIndex = indexes
    .filter((index) => index !== -1)
    .map((index) =>
      index === 'spacer1' || index === 'spacer2' ? (
        <PaginationBS.Item key={index}>...</PaginationBS.Item>
      ) : (
        <PaginationBS.Item
          key={pages[index]}
          active={pages[index] === currentPage}
          onClick={() => onPageChange(pages[index])}
        >
          {pages[index] + 1}
        </PaginationBS.Item>
      ),
    );

  return (
    <>
      {pageNumbers > 1 && (
        <PaginationBS size={totalItems} className='mt-3'>
          <PaginationBS.Prev disabled={currentPage === 0} onClick={onPrevPage}>
            <ChevronDoubleLeftIcon className='icon icon-xs' />
          </PaginationBS.Prev>
          {pagesByIndex}

          <PaginationBS.Next disabled={currentPage === pages.length - 1} onClick={onNextPage}>
            <ChevronDoubleRightIcon className='icon icon-xs' />
          </PaginationBS.Next>
        </PaginationBS>
      )}
      {totalItems > 0 ? (
        <small className='fw-normal small mt-4 mt-lg-0'>
          Showing items <b>{currentPage * limit + 1}</b>
          {' to '}
          <b>
            {totalItems < (currentPage + 1) * limit ? totalItems : (currentPage + 1) * limit}
          </b>{' '}
          out of <b>{totalItems}</b> documents
        </small>
      ) : (
        <small className='fw-normal small mt-4 mt-lg-0'>Not found any items</small>
      )}
    </>
  );
};
