import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { SubscriptionStatus, SubscriptionPlans, AccountDetails, CreditsHistory } from '#components';
import { useFetchSubscriptionStatusQuery } from '#store';

const AccountPage = () => {
  const { isLoading } = useFetchSubscriptionStatusQuery();

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col className='mb-4'>
          <SubscriptionStatus />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className='mb-4 flex-grow-1'>
          <SubscriptionPlans />
        </Col>
        <Col xs={12} lg={6} className='mb-4 flex-grow-1'>
          <CreditsHistory />
        </Col>
      </Row>
      <Row>
        <Col className='mb-4'>
          <AccountDetails />
        </Col>
      </Row>
    </>
  );
};

export default AccountPage;
