import { baseApi } from './baseApi';

const subscriptionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscriptionStatus: builder.query({
      query: () => ({
        url: '/permission-status',
        method: 'GET',
      }),
      transformResponse: (data) => ({
        ...data,
        current_subs: data.current_subs.map((sub) => ({
          ...sub,
          periodType: sub.sub_freq.toLowerCase().includes('month') ? 'month' : 'year',
          currency: sub.currency === 'pln' ? 'zł' : sub.currency,
        })),
      }),
    }),

    fetchProducts: builder.query({
      query: ({ getAllProducts }) => ({
        url: '/stripe/subscribable-product',
        method: 'GET',
        headers: getAllProducts ? { Authorization: null } : {}, // skip Authorization header to fetch all products
      }),
      transformResponse: (data) =>
        data.map((product) => ({
          ...product,
          periodType: product.freq.toLowerCase().includes('month') ? 'month' : 'year',
          currency: product.currency === 'pln' ? 'zł' : product.currency,
        })),
    }),

    fetchCounter: builder.query({
      query: ({ offset, limit }) => ({
        url: `/counter?limit=${limit}&offset=${offset}`,
        method: 'GET',
      }),
    }),

    fetchStripePortal: builder.mutation({
      query: () => ({
        url: '/stripe/customer-portal/', // on purpose use of '/' at the end
        method: 'POST',
      }),
    }),

    fetchStripeCheckout: builder.mutation({
      query: ({ price_id }) => ({
        url: '/stripe/checkout/', // on purpose use of '/' at the end
        method: 'POST',
        body: { price_id },
      }),
    }),

    consentConsumerRights: builder.mutation({
      query: (consent) => ({
        url: '/consumer-rights-consent/', // on purpose use of '/' at the end
        method: 'POST',
        body: { consent },
      }),
    }),
  }),
});

export const {
  useFetchSubscriptionStatusQuery,
  useFetchProductsQuery,
  useFetchCounterQuery,
  useFetchStripePortalMutation,
  useFetchStripeCheckoutMutation,
  useConsentConsumerRightsMutation,
} = subscriptionApi;
