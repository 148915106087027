import { createSlice } from '@reduxjs/toolkit';
import { authLogout } from '#store/actions/authActions';

const doctorSlice = createSlice({
  name: 'doctor',
  initialState: {
    id: null,
    role: null,
    name: ' ',
    email: ' ',
    photo: ' ',
  },
  reducers: {},
  extraReducers: {
    [authLogout.fulfilled]: (state) => {
      state.id = null;
      state.name = null;
      state.email = '';
      state.photo = '';
      state.role = '';
    },
  },
});

export const doctorReducer = doctorSlice.reducer;
export const doctorReducerPath = doctorSlice.name;
