import { baseApi } from './baseApi';

const TAG = 'LaboratoryTests';

// eslint-disable-next-line no-unused-vars
const makeCompareQueries =
  (tag) =>
  async (id, { dispatch, queryFulfilled, getCacheEntry }) => {
    const entryData = getCacheEntry()?.data?.results || [];

    try {
      const { data } = await queryFulfilled;
      const { results } = data;

      const removedDocs = entryData.filter((oldDoc) => {
        return !results.find((newDoc) => newDoc.id === oldDoc.id);
      });

      if (removedDocs.length > 0) {
        const docTags = removedDocs.map(({ id }) => ({ type: TAG, id }));
        dispatch(baseApi.util.invalidateTags([TAG, ...docTags, tag]));
      }
    } catch (err) {
      // `onError` side-effect
      console.log('errrror', err);
    }
  };

const laboratoryTestsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchLaboratoryTestGroups: builder.query({
      query: ({ offset, limit, language }) => ({
        url: '/user-biomarkers/groups/',
        method: 'GET',
        params: { offset, limit, language },
      }),
      transformResponse: (response) => {
        if (!response || typeof response !== 'object') {
          return { results: [], count: 0, float_values: {}, string_values: {} };
        }

        const float_values = response.float_values || {};
        const string_values = response.string_values || {};

        // Combine float_values and string_values into a single results array
        const results = [
          ...Object.entries(float_values).map(([key, value]) => ({ key, value, type: 'float' })),
          ...Object.entries(string_values).map(([key, value]) => ({ key, value, type: 'string' })),
        ];
        const count = results.length;

        return {
          results,
          count,
          float_values,
          string_values,
        };
      },
      providesTags: [TAG],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useFetchLaboratoryTestGroupsQuery } = laboratoryTestsApi;
