import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

export function DiagnosisForm({ form }) {
  const { register } = form;

  return (
    <>
      <Card className='mb-4'>
        <Card.Header className='text-center'>
          <h4>Podaj treść diagnozy</h4>
        </Card.Header>
        <Card.Body>
          <Row className='align-items-center'>
            <Col xs={12} className='mb-3'>
              <Form.Group id='note'>
                <Form.Label>Treść pisemna</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={12}
                  id='lab-note'
                  name='lab-note'
                  {...register('text_content', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
