import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Headers from './Headers';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Headers />
        <ToastContainer />
        <App />
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>,
);
