import React, { useMemo, useState, useRef } from 'react';
import { Stage, Layer, Line, Image, Circle } from 'react-konva';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useComponentSize } from '#hooks';
import { PencilIcon, MinusCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const TOOLS = {
  BRUSH: 'brush',
  ERASE: 'erase',
};
const SIZE_LIST = [40, 30, 20, 12, 8];

export const ImageEditor = ({ image, editorRef }) => {
  const { t } = useTranslation();

  const [lines, setLines] = useState([]);
  const [mode, setMode] = useState(TOOLS.BRUSH);
  const [size, setSize] = useState(SIZE_LIST[1]);
  const [mousePosition, setMousePosition] = useState();
  const isDrawing = useRef(false);
  const componentSize = useComponentSize();

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { mode, size, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();

    setMousePosition(point);

    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }

    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const handleMouseLeave = () => {
    setMousePosition(null);
  };

  const imageElement = useMemo(() => {
    if (!image) return null;
    const imageElement = new window.Image();
    imageElement.src = URL.createObjectURL(image);
    return imageElement;
  }, [image]);

  const imageDimension = useMemo(() => {
    if (!componentSize) return;
    if (!imageElement) return;

    const widthScale = componentSize.width / imageElement.width;
    const heightScale = componentSize.height / imageElement.height;
    const imageScale = Math.min(widthScale, heightScale);

    editorRef.imageScale = imageScale; // save used scale for image download

    const imageHeight = imageElement.height * imageScale;
    const imageWidth = imageElement.width * imageScale;

    return {
      height: imageHeight,
      width: imageWidth,
      scale: imageScale,
    };
  }, [imageElement, componentSize]);

  if (!imageElement) return;

  return (
    <div className='image-editor'>
      <div className='image-editor__canvas' ref={componentSize.ref}>
        <Stage
          ref={editorRef}
          width={imageDimension?.width || undefined}
          height={imageDimension?.height || undefined}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
          onMousemove={handleMouseMove}
          onTouchMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseup={handleMouseUp}
        >
          <Layer>
            <Image
              scaleY={imageDimension?.scale || 1}
              scaleX={imageDimension?.scale || 1}
              image={imageElement}
            />
          </Layer>
          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke='white'
                strokeWidth={line.size}
                lineCap='round'
                lineJoin='round'
                globalCompositeOperation={
                  line.mode === TOOLS.BRUSH ? 'source-over' : 'destination-out'
                }
              />
            ))}
          </Layer>
          <Layer>
            {mousePosition && (
              <Circle
                x={mousePosition.x}
                y={mousePosition.y}
                fill='#e5e7eb'
                opacity={0.4}
                radius={size / 2}
              />
            )}
          </Layer>
        </Stage>
      </div>

      <div className='image-editor__tools'>
        <ButtonGroup>
          <Button
            size='sm'
            variant={mode === TOOLS.BRUSH ? 'secondary' : 'outline-secondary'}
            onClick={() => setMode(TOOLS.BRUSH)}
          >
            <PencilIcon className='icon icon-xs' />
            <span>{t(`image.${TOOLS.BRUSH}`)}</span>
          </Button>
          <Button
            size='sm'
            variant={mode === TOOLS.ERASE ? 'secondary' : 'outline-secondary'}
            onClick={() => setMode(TOOLS.ERASE)}
          >
            <MinusCircleIcon className='icon icon-xs' />
            <span>{t(`image.${TOOLS.ERASE}`)}</span>
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          {SIZE_LIST.map((sizeOption) => (
            <Button
              key={sizeOption}
              size='xs'
              variant={sizeOption === size ? 'secondary' : 'outline-secondary'}
              onClick={() => setSize(sizeOption)}
            >
              <div
                className='image-editor__circle-icon'
                style={{
                  width: sizeOption,
                  height: sizeOption,
                }}
              />
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
};
