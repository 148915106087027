import { useForm } from 'react-hook-form';
import { useErrorTranslation } from './useErrorTranslation';
import { useMemo } from 'react';

const getFormError = ({ field, errors, translateError }) => {
  const translationKey =
    errors?.[field]?.type === 'validate' ? errors?.[field]?.message : errors?.[field]?.type;

  return translateError(translationKey);
};

export const useCustomForm = (...args) => {
  const form = useForm(...args);

  const { errors } = form.formState;

  const translateError = useErrorTranslation();

  const translateFormError = useMemo(() => {
    return (field) =>
      getFormError({
        field,
        errors,
        translateError,
      });
  }, [errors, translateError]);

  return {
    ...form,
    translateFormError,
  };
};
