import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useFetchMedicalDocumentProcessingQuery,
  useFetchMedicalDocumentReportingQuery,
  useLazyFetchMedicalDocumentQuery,
  useFetchHealthReportsProcessingQuery,
  useLazyFetchHealthReportQuery,
} from '#store';
import { DocumentWatcher } from './DocumentWatcher';
import { Routes } from '#Routes';

export const WatcherManager = () => {
  const { t } = useTranslation();
  return (
    <>
      <DocumentWatcher
        useFetchDocuments={useFetchMedicalDocumentProcessingQuery}
        useLazyFetchDocumentQuery={useLazyFetchMedicalDocumentQuery}
        getStatusField={(d) => d.process_status}
        pagePath={Routes.MedicalDocument.path}
        title={[t('watcher-manager.title1-1'), t('watcher-manager.title1-2')]}
        text={[t('watcher-manager.text1-1'), t('watcher-manager.text1-2')]}
      />
      <DocumentWatcher
        useFetchDocuments={useFetchMedicalDocumentReportingQuery}
        useLazyFetchDocumentQuery={useLazyFetchMedicalDocumentQuery}
        getStatusField={(d) => d.report_status}
        pagePath={Routes.MedicalDocument.path}
        title={[t('watcher-manager.title2-1'), t('watcher-manager.title2-2')]}
        text={[t('watcher-manager.text2-1'), t('watcher-manager.text2-2')]}
      />
      <DocumentWatcher
        useFetchDocuments={useFetchHealthReportsProcessingQuery}
        useLazyFetchDocumentQuery={useLazyFetchHealthReportQuery}
        getStatusField={(d) => d.process_status}
        pagePath={Routes.HealthReport.path}
        title={[t('watcher-manager.title3-1'), t('watcher-manager.title3-2')]}
        text={[t('watcher-manager.text3-1'), t('watcher-manager.text3-2')]}
      />
    </>
  );
};
