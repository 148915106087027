import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useFetchMedicalDocumentQuery } from '#store';
import { MedicalDocumentForm } from '#components';

const MedicalDocumentCreatePage = () => {
  const params = useParams();
  const { data, isLoading } = useFetchMedicalDocumentQuery({ id: params.id });

  return (
    <>
      {isLoading ? (
        <div className='d-flex justify-content-center px-8 py-8'>
          <Spinner />
        </div>
      ) : (
        <MedicalDocumentForm data={data} type='EDIT' />
      )}
    </>
  );
};

export default MedicalDocumentCreatePage;
