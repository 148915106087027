import { isRejected } from '@reduxjs/toolkit';
import { authLogout } from '#store/actions';

export const authError = (api) => (next) => (action) => {
  if (isRejected(action)) {
    if (action.payload?.status === 401) {
      if (!action.type.includes(authLogout.typePrefix)) {
        return api.dispatch(authLogout());
      }
    }
  }

  return next(action);
};
