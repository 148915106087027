import { Outlet } from 'react-router-dom';
import React from 'react';
import { useState } from 'react';
import { useBreakpoint, BREAKPOINT, belowBreakpoint } from '#hooks';

import { Sidebar, Topbar, Footer } from '#layout';

import '#scss/volt.scss';

const Layout = () => {
  const { below } = useBreakpoint();

  if (below(BREAKPOINT.SM)) {
    localStorage.setItem('sidebarContracted', false);
  }

  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true;
  };

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible());

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  return (
    <>
      <Sidebar
        contracted={contractSidebar}
        onMouseEnter={toggleMouseOver}
        onMouseLeave={toggleMouseOver}
      />

      <main className='content'>
        <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
          <Topbar toggleContracted={toggleContracted} />

          <div className='flex-grow-1'>
            <Outlet />
          </div>

          <div>
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Layout;
