import React from 'react';
import { useFetchHealthReportsQuery } from '#store';
import { HealthReportWidget } from '#atoms/HealthReportWidget';
import { generatePath } from 'react-router-dom';
import { Routes } from '#Routes';
import { useTranslation } from 'react-i18next';

export const HealthReportsListWidget = () => {
  const { t } = useTranslation();
  const query = {
    offset: 0,
    limit: 5,
  };

  const { data: healthReports, isLoading } = useFetchHealthReportsQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  const mappedReports =
    healthReports?.results.map((report) => ({
      title: t('dashboard.health-report'),
      id: report.id,
      startDate: report.created,
      reportTitle: report.report_title,
      link: generatePath(Routes.HealthReport.path, { id: report.id }),
    })) || [];

  return <HealthReportWidget data={mappedReports} isLoading={isLoading} />;
};
