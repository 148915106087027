import { createSlice } from '@reduxjs/toolkit';
import { authLogout } from '#store/actions/authActions';

const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    id: null,
    role: null,
    name: '',
    email: '',
    age: '',
    gender: '',
    height: '',
    weight: '',
    allergies: '',
    other_conditions: '',
    medications: '',
    overview: '',
    sub_type: '',
    has_paid: '',
  },
  reducers: {
    setPatient: (state, { payload }) => {
      state.id = payload.id;
      state.name = payload.name;
      state.email = payload.email;
      state.role = payload.role;
      state.age = payload.age;
      state.gender = payload.gender;
      state.height = payload.height;
      state.weight = payload.weight;
      state.allergies = payload.allergies;
      state.other_conditions = payload.other_conditions;
      state.medications = payload.medications;
      state.overview = payload.overview;
      state.sub_type = payload.sub_type;
      state.has_paid = payload.has_paid;

      // unused API fields:
      // interview
      // is_active
      // is_verified
      // profile_pic
      // sub_status
    },
  },
  extraReducers: {
    [authLogout.fulfilled]: (state) => {
      state.id = null;
      state.role = null;
      state.name = '';
      state.email = '';
      state.age = '';
      state.gender = '';
      state.height = '';
      state.weight = '';
      state.allergies = '';
      state.other_conditions = '';
      state.medications = '';
      state.overview = '';
      state.sub_type = '';
      state.has_paid = '';
    },
  },
});

export const patientActions = patientSlice.actions;
export const patientReducer = patientSlice.reducer;
export const patientReducerPath = patientSlice.name;
