import React from 'react';
import { Badge, ListGroup, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';

const SubscriptionPlansItem = ({ subscription, isLast }) => {
  const { t } = useTranslation();

  const { sub_type, periodType, period_end, has_paid, cancel_at_period_end } = subscription,
    periodEndDate = moment(period_end).format('DD MMMM YYYY');

  if (!has_paid) return;

  return (
    <ListGroup.Item className={cx('py-3', { 'border-bottom': !isLast })}>
      <Row className='align-items-center'>
        <Col>
          <h6 className='mb-1'>
            {t(`products.plan.${sub_type}.title`)}
            <Badge text='primary' className='bg-gray-100 ms-1'>
              {t(`periodically.${periodType}`).toLowerCase()}
            </Badge>
          </h6>
          {cancel_at_period_end ? (
            <small>
              {t('account.subscription-plan.expires-at')} {periodEndDate}
              <OverlayTrigger
                overlay={
                  <Tooltip className='m-0'>
                    {t('account.subscription-plan.automatically-renew-disabled')}
                  </Tooltip>
                }
              >
                <InformationCircleIcon className='icon icon-xxs text-warning ms-2 mb-1' />
              </OverlayTrigger>
            </small>
          ) : (
            <small>
              {t('account.subscription-plan.automatically-renew-at')} {periodEndDate}
            </small>
          )}
        </Col>
        <Col xs='auto'>
          <Badge bg='success' className='rounded px-3 py-2'>
            <span className='text-uppercase fw-bolder'>{t('account.subscription-plan.paid')}</span>
          </Badge>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default React.memo(SubscriptionPlansItem);
