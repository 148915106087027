import React from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// import { useProfileMutation } from '#store';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from '#Routes';

export default function ProfileUpdatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  // const [update] = useProfileMutation();

  const patient = useSelector((state) => {
    return state.patient;
  });

  const onSubmit = async (data) => {
    const formData = { ...data, interview: true };

    // try {
    //   await update({ id: patient.id, ...formData });
    //   navigate(Routes.Profile.path);
    // } catch (e) {
    //   console.log(e);
    // }
  };

  return (
    <Card border='0' className='shadow mb-4'>
      <Card.Body>
        <h5 className='mb-4'>{t('profile.title')}</h5>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4} className='mb-3'>
              <Form.Group id='name'>
                <Form.Label>{t('profile.name')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.name}
                  {...register('name', { required: true })}
                />
              </Form.Group>
            </Col>
            <Col md={4} className='mb-3'>
              <Form.Group id='age'>
                <Form.Label>{t('profile.age')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.age}
                  {...register('age', { required: true })}
                />
              </Form.Group>
            </Col>
            <Col md={4} className='mb-3'>
              <Form.Group id='gender'>
                <Form.Label>{t('profile.gender')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.gender}
                  {...register('gender', { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md={4} className='mb-3'>
              <Form.Group id='weight'>
                <Form.Label>{t('profile.weight')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.weight}
                  {...register('weight', { required: true })}
                />
              </Form.Group>
            </Col>
            <Col md={4} className='mb-3'>
              <Form.Group id='height'>
                <Form.Label>{t('profile.height')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.height}
                  {...register('height', { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group id='medications'>
                <Form.Label>{t('profile.medications')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.medications}
                  {...register('medications', { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group id='allergies'>
                <Form.Label>{t('profile.allergies')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.allergies}
                  {...register('allergies', { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group id='other_conditions'>
                <Form.Label>{t('profile.other_conditions')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  defaultValue={patient.other_conditions}
                  {...register('other_conditions', { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='mt-3'>
            <Button variant='gray-800' type='submit' className='mt-2 animate-up-2'>
              {t('profile.submit')}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}
