import React, { useEffect, useState, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { parse } from 'csv-parse/browser/esm/sync';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList as List } from 'react-window';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import file from './icd9.csv';

function ICD9List() {
  const height = 35;
  const { options, children, maxHeight, getValue } = this.props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      name='icd9type'
    >
      {({ index, style }) => (
        <div name='icd9type' style={style}>
          {children[index]}
        </div>
      )}
    </List>
  );
}

export function ICD9Input({ form, label, initialValue }) {
  const [rawData, setRawData] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const { i18n } = useTranslation();
  const { control } = form;

  useEffect(() => {
    fetch(file)
      .then((response) => response.text())
      .then((data) => {
        const test = parse(data, {
          skip_empty_lines: false,
        });
        setRawData(test.slice(2));
      });
  }, []);

  const laboratoryExaminations = useMemo(() => {
    if (!rawData.length) return [];

    const laboratoryTests = rawData.map((laboratory) => {
      const localizedName = i18n.language === 'pl' ? laboratory[2] : laboratory[3];
      return {
        value: laboratory[1],
        label: `${laboratory[1]} - ${localizedName}`,
        code: laboratory[1],
        namePl: laboratory[2],
        nameEn: laboratory[3],
      };
    });

    const jsonObject = laboratoryTests.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    return Array.from(uniqueSet).map(JSON.parse);
  }, [rawData, i18n.language]);

  useEffect(() => {
    if (laboratoryExaminations.length > 0) {
      const valueToFind = initialValue?.value || initialValue;
      if (valueToFind) {
        const foundIcd = laboratoryExaminations.find((icd) => icd.value === valueToFind);
        if (foundIcd) {
          if (initialValue?.namePl || initialValue?.nameEn) {
            setSelectedValue({
              ...foundIcd,
              namePl: initialValue.namePl || foundIcd.namePl,
              nameEn: initialValue.nameEn || foundIcd.nameEn,
              currentLanguage: initialValue.currentLanguage || i18n.language,
              label: `${foundIcd.value} - ${
                initialValue.currentLanguage === 'pl'
                  ? initialValue.namePl || foundIcd.namePl
                  : initialValue.nameEn || foundIcd.nameEn
              }`,
            });
          } else {
            setSelectedValue({
              ...foundIcd,
              currentLanguage: i18n.language,
              label: `${foundIcd.value} - ${
                i18n.language === 'pl' ? foundIcd.namePl : foundIcd.nameEn
              }`,
            });
          }
        }
      }
    }
  }, [initialValue, laboratoryExaminations, i18n.language]);

  return laboratoryExaminations.length > 0 ? (
    <Form.Group className='select-box'>
      <Controller
        control={control}
        name={label}
        render={({ field: { onChange, ref } }) => (
          <CreatableSelect
            key={i18n.language}
            inputRef={ref}
            components={{ ICD9List }}
            options={laboratoryExaminations}
            value={selectedValue}
            type='text'
            name={label}
            onChange={(val) => {
              const updatedValue = {
                ...val,
                currentLanguage: i18n.language,
              };
              setSelectedValue(updatedValue);
              onChange(updatedValue);
            }}
          />
        )}
      />
    </Form.Group>
  ) : (
    ''
  );
}
