import { createSlice } from '@reduxjs/toolkit';

const googleSlice = createSlice({
  name: 'google',
  initialState: {
    isLoggedIn: false,
    accessToken: null,
    role: null,
  },
  reducers: {
    googleLoginSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.role = action.payload.role;
    },
    googleLogoutSuccess: (state, action) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.role = null;
    },
  },
});

export const googleActions = googleSlice.actions;
export const googleReducer = googleSlice.reducer;
export const googleReducerPath = googleSlice.name;
