import React from 'react';
import { Button, Card, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchSubscriptionStatusQuery } from '#store';
import { Link } from 'react-router-dom';
import { Routes } from '#Routes';

const SubscriptionStatus = () => {
  const { t } = useTranslation();
  const { data } = useFetchSubscriptionStatusQuery();

  if (!data) return;

  const isSubscriptionActive = data.current_subs.length > 0;
  const remainingCreds = data.creds - data.counts;
  const hasRemainingCreds = remainingCreds > 0;

  return (
    <Card border='0' className='shadow p-0 p-md-4 h-100'>
      <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center'>
        <h2 className='mb-3'>
          {t('account.subscription-status.subscription')}{' '}
          {isSubscriptionActive ? (
            <Badge bg='secondary' className='p-2'>
              {t('account.subscription-status.status-active')}
            </Badge>
          ) : (
            <Badge bg='warning' pill className='p-2'>
              {t('account.subscription-status.status-inactive')}
            </Badge>
          )}
        </h2>

        <div className='my-3'>
          {isSubscriptionActive ? (
            <>
              {remainingCreds <= 0 && (
                <p>{t('account.subscription-status.desc-when-active-no-credits')}</p>
              )}
              {remainingCreds > 0 && (
                <p style={{ whiteSpace: 'break-spaces' }}>
                  {t('account.subscription-status.desc-when-active-with-credits')}{' '}
                  <Badge>{data.creds - data.counts}</Badge>
                </p>
              )}
            </>
          ) : (
            <p>{t('account.subscription-status.desc-when-inactive')}</p>
          )}
          <p style={{ whiteSpace: 'break-spaces' }}>
            {t('account.subscription-status.extra-credits')}{' '}
            <Badge>{data.extra_credits}</Badge>
          </p>
        </div>

        {isSubscriptionActive ? (
          <Button as={Link} variant='secondary' to={Routes.AccountSubscribe.path}>
            {hasRemainingCreds
              ? t('account.subscription-status.change-plan')
              : t('account.subscription-status.upgrade-plan')}
          </Button>
        ) : (
          <Button
            as={Link}
            variant='success'
            className='text-white px-5'
            size={'lg'}
            to={Routes.AccountSubscribe.path}
          >
            {t('account.subscription-status.activate-plan')}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default React.memo(SubscriptionStatus);
