import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLogged } from '#store';
import { WatcherManager } from '#components';

const ProtectedPage = () => {
  const isLogged = useSelector(selectIsLogged);

  return isLogged ? (
    <>
      <WatcherManager />
      <Outlet />
    </>
  ) : (
    <Navigate to='/auth/login' />
  );
};

export default ProtectedPage;
