import React, { useCallback } from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchSubscriptionStatusQuery, useFetchStripePortalMutation } from '#store';
import SubscriptionPlansItem from './SubscriptionPlansItem';

const SubscriptionPlans = () => {
  const { t } = useTranslation();

  const { data } = useFetchSubscriptionStatusQuery(undefined, { refetchOnMountOrArgChange: true });

  const [fetchStripePortal] = useFetchStripePortalMutation();

  const goToStripePortal = useCallback(async () => {
    const { data } = await fetchStripePortal();

    if (data?.url) window.location.href = data.url;
  }, [fetchStripePortal]);

  if (!data || !data.stripe_user) return;

  return (
    <Card border='0' className='shadow p-0 p-md-4 h-100'>
      <Card.Header className='border-bottom p-3 d-flex'>
        <h5>{t('account.subscription-plan.list')}</h5>
      </Card.Header>
      <Card.Body className='px-0 py-0'>
        <ListGroup>
          {data.current_subs?.map((subscription, index) => (
            <SubscriptionPlansItem
              key={subscription.sub_id}
              subscription={subscription}
              isLast={index === data.current_subs.length - 1}
            />
          ))}
        </ListGroup>
      </Card.Body>
      <Card.Footer className='d-flex justify-content-center'>
        <Button variant='secondary' onClick={goToStripePortal}>
          {t('account.subscription-plan.manage')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default React.memo(SubscriptionPlans);
