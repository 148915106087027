import React, { useState, useMemo } from 'react';
import { Col, Row, Card, FormCheck, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchProductsQuery, useFetchSubscriptionStatusQuery } from '#store';
import ProductsPricingItem from './ProductsPricingItem';

const ProductsPricing = ({ showActiveProducts = false, onSelectProduct }) => {
  const { t } = useTranslation();

  const [isPriceMonthly, setIsPriceMonthly] = useState(true);

  const { data: products = [], isLoading: isLoadingProducts } = useFetchProductsQuery(
    { getAllProducts: true },
    { refetchOnMountOrArgChange: true },
  );

  const { data: subscriptionStatus, isLoading: isLoadingSubscriptionStatus } =
    useFetchSubscriptionStatusQuery(undefined, {
      skip: !showActiveProducts,
      refetchOnMountOrArgChange: true,
    });

  const currentSubs = useMemo(() => subscriptionStatus?.current_subs || [], [subscriptionStatus]);

  const visibleProducts = useMemo(
    () =>
      products
        .filter(({ periodType }) => periodType === (isPriceMonthly ? 'month' : 'year'))
        .map((product) => ({
          ...product,
        })) || [],
    [products, isPriceMonthly],
  );

  const currency = visibleProducts[0]?.currency;

  const freeProduct = useMemo(
    () => ({
      price: 0,
      currency,
      periodType: isPriceMonthly ? 'month' : 'year',
      name: 'FREE',
    }),
    [currency, isPriceMonthly],
  );

  const showFreeProduct = currentSubs.length === 0;

  if (isLoadingProducts || isLoadingSubscriptionStatus) {
    return (
      <div className='text-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <Card border='0' className='shadow mb-5'>
      <Card.Body>
        <Row className='text-gray justify-content-center'>
          <Col xs={12} className='d-flex align-items-center justify-content-center pb-4'>
            <h5 className='fw-normal me-4 mt-2 text-capitalize'>{t('periods.month')}</h5>
            <FormCheck type='switch'>
              <FormCheck.Input
                type='checkbox'
                id='billingSwitch'
                value={isPriceMonthly}
                onChange={() => setIsPriceMonthly((v) => !v)}
              />
              <FormCheck.Label htmlFor='billingSwitch' />
            </FormCheck>
            <h5 className='fw-normal ms-4 mt-2 text-capitalize'>{t('periods.year')}</h5>
          </Col>
          {showFreeProduct && (
            <Col xs={12} md={6} xl={3} className='mt-3'>
              <ProductsPricingItem
                product={freeProduct}
                isSubscriptionActive={showActiveProducts}
                selectProduct={onSelectProduct}
              />
            </Col>
          )}
          {visibleProducts?.map((product) => {
            const subscriptions = currentSubs.filter(({ sub_type }) => sub_type === product.name);
            const subscription =
              subscriptions?.find(({ periodType }) => periodType === product.periodType) ||
              subscriptions[0];

            return (
              <Col
                xs={12}
                md={6}
                xl={showFreeProduct ? 3 : 4}
                key={product.product_id}
                className='mt-3'
              >
                <ProductsPricingItem
                  product={product}
                  selectProduct={onSelectProduct}
                  isSubscriptionActive={Boolean(subscription)}
                  subscription={subscription}
                />
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default React.memo(ProductsPricing);
