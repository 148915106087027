import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { WithContext as ReactTags } from 'react-tag-input';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export const TagsInput = ({
  form,
  title,
  placeholder,
  initialValues,
  inputPosition = 'bottom',
  onChange: onChangeCallback = () => {},
  translationKey,
}) => {
  const { control } = form;
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (Array.isArray(initialValues)) {
      const formattedTags = initialValues.map((tag) => ({
        id: tag,
        text: tag,
      }));
      setTags(formattedTags);
    }
  }, [initialValues]);

  const handleDelete = (i, onChange) => {
    const newTags = tags.filter((_, index) => index !== i);
    setTags(newTags);
    const mappedTags = newTags.map((tag) => tag.text);
    onChange(mappedTags);
    onChangeCallback(mappedTags);
  };

  const handleAddition = (tag, onChange) => {
    const newTags = [...tags, { id: tag.text, text: tag.text }];
    setTags(newTags);
    const mappedTags = newTags.map((t) => t.text);
    onChange(mappedTags);
    onChangeCallback(mappedTags);
  };

  return (
    <Form.Group id='tags'>
      {(title || translationKey) && (
        <Form.Label>{translationKey ? t(translationKey) : title}</Form.Label>
      )}
      <Controller
        control={control}
        name='tags'
        render={({ field: { onChange } }) => (
          <div className='react-tags-wrapper'>
            <ReactTags
              tags={tags}
              delimiters={delimiters}
              handleDelete={(i) => handleDelete(i, onChange)}
              handleAddition={(tag) => handleAddition(tag, onChange)}
              inputFieldPosition={inputPosition}
              placeholder={placeholder || t('common.enter-tags')}
              classNames={{
                tags: 'ReactTags__tags',
                tagInput: 'ReactTags__tagInput',
                tagInputField: 'form-control',
                selected: 'ReactTags__selected',
                tag: `ReactTags__tag badge bg-primary me-1 ${
                  inputPosition === 'bottom' ? 'mb-2' : 'mt-2'
                }`,
                remove: 'ReactTags__remove btn btn-close btn-close-white hide-text',
              }}
              allowDragDrop={false}
              allowDeleteFromEmptyInput={false}
              autofocus={false}
            />
          </div>
        )}
      />
    </Form.Group>
  );
};
