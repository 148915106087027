import React, { useState, useCallback, useRef } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as pdfjs from 'pdfjs-dist';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker';
import { ImageEditor, ImagePreview } from '#atoms';
import cx from 'classnames';
import './styles.scss';

export function ImageInput({ name, form }) {
  const { t } = useTranslation();

  const [previewIndex, setPreviewIndex] = useState(-1);
  const [isFocused, setIsFocused] = useState(false);
  const imageEditorRef = useRef();

  const { watch, setValue } = form;
  const images = watch(name);

  const handleClose = () => setPreviewIndex(-1);

  const handleRemove = useCallback(
    (index) => {
      const nextImages = [...images.slice(0, index), ...images.slice(index + 1)];

      setValue(name, nextImages);
    },
    [name, images, setValue],
  );

  const handleUpdate = useCallback(async () => {
    const imageBlob = await imageEditorRef.current.toBlob({
      mimeType: 'image/jpeg',
      quality: 1,
      pixelRatio: 1 / imageEditorRef.imageScale, // revert image scaling
    });

    // set image file name and format
    const imageName = `image_edited_${Date.now()}.jpeg`;

    const imageFile = new File([imageBlob], imageName, { type: imageBlob.type });

    const nextImages = [
      ...images.slice(0, previewIndex),
      imageFile,
      ...images.slice(previewIndex + 1),
    ];

    setValue(name, nextImages);
    setPreviewIndex(-1);
  }, [name, images, previewIndex, setValue]);

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const processPdfFile = useCallback(async (file) => {
    pdfjs.GlobalWorkerOptions.workerSrc = 'node_modules/pdfjs-dist/build/pdf.worker.mjs';
    const fileReader = new FileReader();
    const fileName = file.name.replace(/\.[^/.]+$/, '');
    const data = [];

    return new Promise((resolve, reject) => {
      try {
        fileReader.onload = async function () {
          const typedarray = new Uint8Array(this.result);
          const pdf = await pdfjs.getDocument(typedarray).promise;

          const canvasdiv = document.createElement('canvas');

          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);

            const scale = 2;
            const viewport = page.getViewport({ scale: scale });

            const canvas = document.createElement('canvas');
            canvasdiv.appendChild(canvas);

            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = { canvasContext: context, viewport: viewport };

            const renderTask = await page.render(renderContext).promise;

            const pngFile = canvas.toDataURL('image/jpeg');

            data.push(dataURLtoFile(pngFile, `${fileName}-${pageNumber}.jpeg`));
          }

          resolve(data);
        };
      } catch (error) {
        reject([]);
      }

      fileReader.readAsArrayBuffer(file);
    });
  }, []);

  const handleFileUpload = useCallback(
    async (event) => {
      const eventFiles = Object.values(event.target.files);
      const filesPromises = eventFiles.map(async (file) => {
        return file.type === 'application/pdf' ? processPdfFile(file) : file;
      });
      const filesTable = await Promise.all(filesPromises);
      const files = filesTable.flat();
      const currentFiles = watch(name) ?? [];
      const nextImages = [...currentFiles, ...files];

      setValue(name, nextImages);

      event.target.value = null; // allow to reupload same file
    },
    [name, watch, setValue],
  );

  return (
    <>
      <div
        className='image-input'
        onTouchStart={() => setIsFocused(true)}
        onMouseEnter={() => setIsFocused(true)}
        onMouseLeave={() => setIsFocused(false)}
      >
        <Form.Group controlId='formFile' className='mb-3'>
          <Form.Label>{t('forms.image.dragAndDrop')}</Form.Label>
          <Form.Control
            className='image-input__hidden'
            type='file'
            onChange={handleFileUpload}
            accept='.jpg, .jpeg, .png, .pdf'
            multiple
            title='' // hide input tooltip
          />
        </Form.Group>

        <div
          className={cx('image-input__preview-list', {
            // it allows to drag and drop files over images
            'image-input__preview-list--focused': isFocused,
          })}
        >
          {images?.map((image, index) => (
            <ImagePreview
              key={image?.name}
              image={image}
              index={index}
              select={setPreviewIndex}
              remove={handleRemove}
            />
          ))}
        </div>
      </div>

      <Modal size='xl' show={previewIndex > -1} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('forms.image.edit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageEditor image={images?.[previewIndex]} editorRef={imageEditorRef} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={handleUpdate}>
            {t('forms.image.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
