import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const SERVER_URL = process.env.REACT_APP_API_URL;

const googleApi = createApi({
  reducerPath: 'googleAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/api/v1`,
  }),
  endpoints: (builder) => ({
    googleLogin: builder.mutation({
      query: (detail) => ({
        url: '/google/',
        method: 'POST',
        body: detail,
      }),
    }),
  }),
});

export const { useGoogleLoginMutation } = googleApi;
export { googleApi };
