import { useState, useEffect, useMemo } from 'react';
import debounce from 'debounce';

export const BREAKPOINT = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
};

const resolveBreakpoint = (width) => {
  if (width < 576) {
    return BREAKPOINT.XS;
  } else if (width >= 576 && width < 768) {
    return BREAKPOINT.SM;
  } else if (width >= 768 && width < 992) {
    return BREAKPOINT.MD;
  } else if (width >= 992 && width < 1200) {
    return BREAKPOINT.LG;
  } else if (width >= 1200 && width < 1440) {
    return BREAKPOINT.XL;
  } else if (width >= 1440) {
    return BREAKPOINT.XXL;
  }
};

const belowBreakpoint = (bp1, bp2) => {
  const BP = Object.values(BREAKPOINT);
  return BP.indexOf(bp1) <= BP.indexOf(bp2);
};

const aboveBreakpoint = (bp1, bp2) => {
  const BP = Object.values(BREAKPOINT);
  return BP.indexOf(bp1) >= BP.indexOf(bp2);
};

export const useBreakpoint = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return useMemo(() => {
    return {
      below: (bp) => belowBreakpoint(size, bp),
      above: (bp) => aboveBreakpoint(size, bp),
      breakpoint: size,
    };
  }, [size]);
};
