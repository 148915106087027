import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

// import { ICD9Input } from '#atoms';
// import { Languages } from '#const';
import { useFirstRender } from '#hooks';

export function LaboratoryResultsForm({ nestIndex, name, type, form, options }) {
  const { control, register } = form;
  // const { t } = useTranslation();
  const prefix = `json_content.${nestIndex}.values`;

  const isFirstRender = useFirstRender();

  const { fields, remove, append } = useFieldArray({
    control,
    name: prefix,
  });

  useEffect(() => {
    if (isFirstRender && fields.length < 1) {
      append({
        name: '',
        value: '',
        unit: '',
        reference: [],
      });
    }
  }, [isFirstRender, append, fields]);

  return (
    <>
      {fields.length > 0 && (
        <Row>
          <Col xs={4}>
            <Form.Label>Nazwa</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Label>Wartość</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Label>Jednostka</Form.Label>
          </Col>
          <Col xs={3} style={{ textAlign: 'center' }}>
            <Form.Label>Zakres</Form.Label>
          </Col>
        </Row>
      )}

      {fields.map((item, index) => {
        return (
          <Row key={item.id}>
            <Col xs={4} className='mb-3'>
              <Form.Group id='name'>
                <Form.Control type='text' name='name' {...register(`${prefix}.${index}.name`)} />
              </Form.Group>
            </Col>
            <Col xs={2} className='mb-3'>
              <Form.Group id='value'>
                <Form.Control type='text' name='value' {...register(`${prefix}.${index}.value`)} />
              </Form.Group>
            </Col>
            <Col xs={2} className='mb-3'>
              <Form.Group id='unit'>
                <Form.Control type='text' name='unit' {...register(`${prefix}.${index}.unit`)} />
              </Form.Group>
            </Col>
            <Col xs={3} className='mb-3'>
              <Form.Group id='reference'>
                <Row>
                  <Col xs={6}>
                    <Form.Control
                      type='text'
                      name='referenceLow'
                      {...register(`${prefix}.${index}.reference[0]`)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Form.Control
                      type='text'
                      name='referenceHigh'
                      {...register(`${prefix}.${index}.reference[1]`)}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col xs={1} className='mb-3'>
              <Button
                type='button'
                variant='close'
                aria-label='Close'
                onClick={() => {
                  remove(index);
                }}
              />
            </Col>
          </Row>
        );
      })}

      <div className='d-grid gap-2'>
        <Button
          variant='outline-primary'
          size='lg'
          className='animate-up-2 d-grid gap-2'
          onClick={() => append({})}
        >
          +
        </Button>
      </div>
    </>
  );
}
