import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import { SurveyQuestionElementBase, ReactQuestionFactory } from 'survey-react-ui';
import { Spinner, ProgressBar } from 'react-bootstrap';
import './timeout.css';

const CUSTOM_TYPE = 'timeout';

export class QuestionTimeoutModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  get timeout() {
    return this.getPropertyValue('timeout');
  }
  set timeout(val) {
    this.setPropertyValue('timeout', val);
  }

  get loaderType() {
    return this.getPropertyValue('loaderType');
  }
  set loaderType(val) {
    this.setPropertyValue('loaderType', val);
  }

  get hideNavigation() {
    return this.getPropertyValue('hideNavigation');
  }
  set hideNavigation(val) {
    this.setPropertyValue('hideNavigation', val);
  }
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: 'timeout',
      default: 2000,
    },
    {
      name: 'loaderType',
      default: 'ProgressBar',
      choices: ['ProgressBar', 'Spinner'],
    },
    {
      name: 'hideNavigation',
      default: true,
      choices: [true, false],
    },
  ],
  function () {
    return new QuestionTimeoutModel('');
  },
  'question',
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionTimeoutModel(name);
});

export class SurveyTimeout extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.survey = props.creator.survey;
    this.state = { progress: 1 };

    if (this.hideNavigation) this.survey.showNavigationButtons = false;
  }
  get question() {
    return this.questionBase;
  }
  get timeout() {
    return this.question.timeout;
  }
  get progress() {
    return this.state.progress;
  }
  get type() {
    return this.question.loaderType;
  }
  get hideNavigation() {
    return this.question.hideNavigation;
  }

  renderLoader(type) {
    switch (type) {
      case 'ProgressBar': {
        return (
          <ProgressBar
            striped
            style={{ transitionDuration: `${Math.ceil(this.timeout / 100) / 10}s` }}
            now={this.progress}
          />
        );
      }
      case 'Loader': {
        return <Spinner style={{ width: '30px', height: '30px' }} />;
      }
      default:
        return <div></div>;
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.survey.nextPage();
      if (this.hideNavigation) this.survey.showNavigationButtons = true;
    }, this.timeout);

    this.setState({ progress: 100 });
  }

  renderElement() {
    return <div>{this.renderLoader(this.type)}</div>;
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(SurveyTimeout, props);
});
