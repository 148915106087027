import useLogin from './useLogin';
import useLogout from './useLogout';
import useRegister from './useRegister';

export const useAuth = () => {
  const { login, redirectWhenLoggedIn } = useLogin();
  const logout = useLogout();
  const register = useRegister();

  return {
    login,
    logout,
    register,
    redirectWhenLoggedIn,
  };
};
