import { baseApi } from './baseApi';

const TAG = 'HealthData';

const healthDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchHealthData: builder.query({
      query: ({ id, offset, limit }) => {
        if (id) {
          return {
            url: `/health-interview/${id}`,
            method: 'GET',
          };
        } else {
          return {
            url: `/health-interview/?limit=${limit}&offset=${offset}`,
            method: 'GET',
          };
        }
      },
      providesTags: [TAG],
    }),
    createHealthData: builder.mutation({
      query: (params) => ({
        url: '/health-interview/',
        method: 'POST',
        body: { data: params },
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const { useFetchHealthDataQuery, useCreateHealthDataMutation } = healthDataApi;
