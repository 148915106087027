import { baseApi } from './baseApi';

const TAG = 'HealthReports';

const HealthReportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchHealthReport: builder.query({
      query: ({ id }) => ({
        url: `/health-reports/${id}/`,
        method: 'GET',
      }),
      providesTags: (data) => (data?.data ? [{ type: TAG, id: data.id }, TAG] : [TAG]),
    }),
    fetchHealthReports: builder.query({
      query: ({ ...params } = {}) => ({
        url: '/health-reports/?' + new URLSearchParams(params).toString(),
        method: 'GET',
      }),
      providesTags: [TAG],
    }),
    fetchHealthReportsProcessing: builder.query({
      query: () => ({
        url: `/health-reports/?process_status=IN_PROGRESS`,
        method: 'GET',
      }),
      providesTags: [TAG],
    }),
    createHealthReport: builder.mutation({
      query: (params) => ({
        url: '/health-reports/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [TAG],
    }),
    deleteHealthReport: builder.mutation({
      query: ({ id }) => ({
        url: `/health-reports/${id}/`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useFetchHealthReportQuery,
  useLazyFetchHealthReportQuery,
  useFetchHealthReportsQuery,
  useFetchHealthReportsProcessingQuery,
  useCreateHealthReportMutation,
  useDeleteHealthReportMutation,
} = HealthReportsApi;
