import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { patientReducerPath, patientReducer } from './slices/patientSlice';
import { doctorReducerPath, doctorReducer } from './slices/doctorSlice';
import { authReducerPath, authReducer } from './slices/authSlice';
import { googleReducerPath, googleReducer } from './slices/googleSlice';
import { authError, serverError } from './middlewares';
import { setupListeners } from '@reduxjs/toolkit/query';
import { baseApi } from './apis/baseApi';

const createStore = () => {
  const persistConfig = {
    key: 'root',
    storage,
    safelist: [authReducerPath, patientReducerPath, doctorReducerPath], // reducers to persist
  };

  const rootReducer = combineReducers({
    [authReducerPath]: authReducer,
    [patientReducerPath]: patientReducer,
    [doctorReducerPath]: doctorReducer,
    [googleReducerPath]: googleReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(baseApi.middleware, authError, serverError);
    },
  });

  setupListeners(store.dispatch);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default createStore;
