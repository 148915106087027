import React from 'react';
import { Card, ListGroup, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  ClockIcon,
  DocumentIcon,
  DocumentSearchIcon,
  DocumentTextIcon,
  DocumentReportIcon,
} from '@heroicons/react/solid';

const IconByProcessType = new Proxy(
  {
    MEDICAL_DOCUMENT: DocumentSearchIcon,
    DOCUMENT_REPORT: DocumentTextIcon,
    HEALTH_REPORT: DocumentReportIcon,
  },
  (ob, prop) => ob[prop] || DocumentIcon,
);

const CreditsHistoryItem = ({ creditCounter }) => {
  const { t } = useTranslation();

  const Icon = IconByProcessType[creditCounter.process_type];

  return (
    <ListGroup.Item className='border-o'>
      <Row className='ps-lg-1'>
        <Col xs='auto'>
          <div className={`icon-shape icon-xs icon-shape-success rounded`}>
            <Icon />
          </div>
        </Col>
        <Col className='ms-n2 mb-3'>
          <h3 className='fs-6 fw-bold mb-1'>
            {t(`account.credits.process-type.${creditCounter.process_type}`)}
          </h3>
          <Card.Text className='mb-1'>{'<document_name_placeholder>'}</Card.Text>
          <div className='d-flex align-items-center'>
            <ClockIcon className='icon icon-xxs text-gray-400 me-1' />
            <small>{moment(creditCounter.created).fromNow()}</small>
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default React.memo(CreditsHistoryItem);
