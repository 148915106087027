import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { Routes } from '#Routes';
import { useSelector } from 'react-redux';
import { selectIsLogged } from '#store';
import { DownloadIcon } from '@heroicons/react/solid';

const DocumentViewer = ({ titleTranslationKey, fileTranslationKey }) => {
  const { t } = useTranslation();
  const isLogged = useSelector(selectIsLogged);

  const title = t(titleTranslationKey);
  const file = t(fileTranslationKey);

  return (
    <main>
      <Container className='vh-100 p-lg-5 d-flex flex-column'>
        <div className='mt-1'>
          <p className='text-center'>
            {isLogged ? (
              <Card.Link
                as={Link}
                to={Routes.Dashboard.path}
                className='d-flex align-items-center justify-content-center'
              >
                <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
                {t('auth.backToDashboard')}
              </Card.Link>
            ) : (
              <Card.Link
                as={Link}
                to={Routes.Home.path}
                className='d-flex align-items-center justify-content-center'
              >
                <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
                {t('auth.backToHomepage')}
              </Card.Link>
            )}
          </p>
        </div>
        <Card border='0' className='shadow p-2 p-lg-4 flex-grow-1'>
          <div>
            <h1 className='text-center'>
              {title}
              <a className='ms-3' href={file} download={title}>
                <DownloadIcon className='icon icon-xx' />
              </a>
            </h1>
          </div>
          <div className='h-100 mt-3'>
            <embed
              type='application/pdf'
              src={file + '#view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=1'}
              className='w-100 h-100'
            />
          </div>
        </Card>
      </Container>
    </main>
  );
};

export default DocumentViewer;
