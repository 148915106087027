import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { Col, Row, Image, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Routes } from '#Routes';
import ErrorImage from '#assets/img/illustrations/500.svg';

export const ServerErrorPage = () => {
  const { t } = useTranslation();

  return (
    <section className='vh-100 d-flex align-items-center justify-content-center'>
      <Container>
        <Row className='align-items-center'>
          <Col xs={12} lg={5} className='order-2 order-lg-1 text-center text-lg-start'>
            <h1 className='mt-5'>{t('serverError.info')}</h1>
            <p className='lead my-4'>{t('serverError.break')}</p>
            <Button
              as={Link}
              variant='gray-800'
              className='d-inline-flex align-items-center justify-content-center mb-4'
              to={Routes.Home.path}
            >
              <ChevronLeftIcon className='icon icon-xs me-2' />
              {t('serverError.back')}
            </Button>
          </Col>
          <Col
            xs={12}
            lg={7}
            className='order-1 order-lg-2 text-center d-flex align-items-center justify-content-center'
          >
            <Image src={ErrorImage} className='img-fluid w-75' />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
